<template>
  <v-app-bar fixed elevate-on-scroll :color="bg">
    <v-banner
      id="notification"
      v-model="data.settings.notificationEnabled"
      single-line
      transition="v-slide-y-reverse-transition"
    >
      {{ data.settings.notificationText }}
      <template v-slot:actions="{ dismiss }">
        <v-icon @click="dismiss" right>mdi-close-circle</v-icon>
      </template>
    </v-banner>
    <v-container class="d-flex">
      <div id="header-logo" @click="$router.push({ path: '/' })">
        <img
          src="/img/iwi-logo.svg"
          alt="Investments &amp; Wealth Institute logo"
        />
        <div id="header-text">
          <h2>InvestmentHelp.org</h2>
          <p class="tagline">You deserve an exceptional advisor</p>
        </div>
      </div>
      <v-spacer></v-spacer>

      <template v-if="!appState.minWrap">
        <v-divider class="mx-1 vue-divider" vertical></v-divider>

        <about-me></about-me>
      </template>

      <!-- <v-spacer></v-spacer> -->
      <v-menu
        content-class="nav-menu"
        transition="slide-y-transition"
        :close-on-content-click="false"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text class="py-8 app-bar bar-icon" v-bind="attrs" v-on="on">
            <v-icon>mdi-magnify</v-icon>
            <div class="supplemental">Search</div>
          </v-btn>
        </template>
        <advisor-search></advisor-search>
      </v-menu>
      <v-menu
        content-class="nav-menu"
        transition="slide-y-transition"
        :close-on-content-click="false"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="py-8 app-bar bar-icon bar-icon-border"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-account</v-icon>
            <div class="supplemental">{{ userIconText }}</div>
          </v-btn>
        </template>

        <profile-menu></profile-menu>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>
  import AboutMe from '@/components/AboutMe'
  import AdvisorSearch from '@/components/AdvisorSearch'
  import ProfileMenu from '@/components/ProfileMenu'

  export default {
    name: 'AppHeader',
    components: { AboutMe, AdvisorSearch, ProfileMenu },
    data() {
      return {
        show: false,
        bg: 'transparent',
      }
    },
    computed: {
      userIconText() {
        return this.appState.loggedIn ? this.user.first_name : 'Login'
      },
    },
    methods: {
      onClickOutside() {
        this.show = false
      },
      changeOpacity() {
        if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
        ) {
          this.bg = '' //the color will take the default color provided in the less file
        } else {
          this.bg = 'transparent'
        }
      },
      closeNotification() {
        // console.log(this.appState.showNotification)
        this.appState.showNotification = false
      },
    },
    updated() {},
    mounted() {
      window.onscroll = () => {
        this.changeOpacity()
      }
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .v-app-bar {
    background-color: @header-dark-blue !important;
    height: auto !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: @invert;
    }

    hr {
      border: none;
    }

    .tagline {
      font-size: 0.75em;
    }

    #header-logo {
      display: flex;
      position: relative;
      cursor: pointer;

      & > img {
        height: 3em;
        width: 3em;
        margin-right: 0.5em;
      }

      h2 {
        font-weight: bold;
        margin: 0 0 0.15em 0;
      }

      p {
        margin-bottom: 0;
      }
    }

    .container {
      align-items: flex-start;
    }

    .bar-icon {
      margin-top: 0.5em;
      padding-top: 22px !important;
      padding-bottom: 22px !important;
    }

    .bar-icon-border {
      border-left: solid @brand-yellow 0.5px;
      border-radius: 0;
    }

    .v-toolbar__content {
      height: auto !important;
      padding: 4px 0;
      flex-direction: column;
    }

    .v-btn__content {
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-transform: capitalize;
    }
    #notification.v-banner {
      display: flex;
      align-items: center;
      background-color: @brand-notification;
      border-color: @brand-notification;
      min-height: 75px;
      width: 100%;
      margin-top: -4px;
      color: @brand-white;
      font-weight: bold;
      font-size: @font-size-uphalf;

      .v-banner__wrapper {
        max-width: 1185px;
        margin: auto;
        border: none;
      }

      .v-banner__text {
        line-height: 2em;
      }
    }
  }

  .nav-menu {
    margin-top: 5em !important;
    // left: 52em !important; //margin-left did not work
    width: 32em;
  }
  .v-menu__content.nav-menu {
    right: 0px !important;
    left: initial !important;
    transform-origin: initial !important;
    box-shadow: 0px 17px 7px 1px rgb(0 0 0 / 15%);
  }

  .supplemental {
    font-size: @font-size-tiny;
    font-weight: 400;
    letter-spacing: 0;
  }

  @media screen and (min-width: 960px) {
    .v-menu__content.nav-menu {
      right: calc((100vw - 1000px) / 2) !important;
    }
  }

  @media screen and (min-width: 1264px) {
    .v-menu__content.nav-menu {
      right: calc((100vw - 1285px) / 2) !important;
    }
  }

  @media screen and (min-width: 600px) {
    .v-app-bar {
      .tagline {
        font-size: 1em;
      }

      #header-logo {
        & > img {
          height: 4.5em;
          width: 4.5em;
        }
      }
    }
  }
  /* // TODO; Added media query based on the one that was already above but in places where i've done
  // responsive I've picked 400px. Need to ask why 600px was picked (and why those specific items) */
  @media screen and (max-width: 599px) {
    // TODO;This shouldn't need to be hardcoded but I can't find why some of the defaults values are weird
    .v-toolbar__content {
      margin: 0 4% 0 0;
      align-items: flex-start;

      .v-btn__content {
        transform: scale(1.1);
      }
    }
    hr {
      position: relative;
      background-color: @brand-yellow !important;
      border: 1px solid @brand-yellow !important;
    }
    #header-logo {
      & > img {
        height: 2.7em !important;
        width: 2.7em !important;
      }
    }
    h2 {
      font-size: 1.075rem !important;
      margin-right: 3rem;
    }
    .supplemental {
      display: none;
    }

    .divider {
      background-color: @brand-yellow;
      width: 3px;
      height: 2.8rem;
      margin: auto;
    }
    .d-flex {
      padding-left: 0;
      padding-right: 0;
      margin: 0 10% 0 3%;
    }
    .vue-divider {
      display: none;
    }
    .tagline {
      //TODO; are we ok making it fo on two lines? if yes then we should increase this
      font-size: 0.6em !important;
      margin-right: -2% !important;
    }
    .v-app-bar {
      margin-bottom: -100px;
    }
    .container {
      margin: 0 auto;
    }
    #header-text {
      width: 13rem;
    }
    .v-btn:not(.v-btn--round) {
      padding: 0;
      min-width: 48px !important;
    }
  }
</style>
