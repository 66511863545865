<template>
  <div class="resource">
    <h2 class="container">Resources &amp; Investment Help</h2>
    <article :key="resource.id">
      <v-container>
        <v-card>
          <v-container>
            <v-row>
              <v-col offset-md="1" cols="10" md="9">
                <h2>
                  {{ resource.title }}
                  <span v-if="resource.acronym">({{ resource.acronym }})</span>
                </h2>
                <p class="subtitle" v-if="resource.subline">
                  {{ resource.subline }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="resource.summary">
              <v-col cols="12" md="8" offset-md="1">
                <p class="summary">
                  {{ resource.summary }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                md="resource.centerColComp.length ? 3 : 8"
                offset-md="1"
                class="left-col"
                v-if="resource.leftColComp.length"
              >
                <aside>
                  <content-matrix
                    :contentArray="resource.leftColComp"
                  ></content-matrix>
                </aside>
              </v-col>
              <v-col
                md="resource.leftColComp.length ? 4 : 8"
                :offset-md="resource.leftColComp.length ? 0 : 1"
                class="center-col"
                v-if="resource.centerColComp.length"
              >
                <content-matrix
                  :contentArray="resource.centerColComp"
                ></content-matrix>
              </v-col>
              <v-col md="4" class="right-col">
                <aside class="resources">
                  <h3>Resources &amp; Education</h3>
                  <resource-other></resource-other>
                </aside>
                <statistic
                  :statId="
                    resource.displayStatistic[0]
                      ? resource.displayStatistic[0].id
                      : null
                  "
                ></statistic>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>

      <v-container class="supplement panel">
        <v-row>
          <v-col>
            <h3>Institute code of professional responsibility</h3>
            <p>
              <em>The Code</em>
              was adopted to promote and maintain a high standard of
              professional conduct in the investment consulting and wealth
              management disciplines. Investments &amp; Wealth Institute
              members, candidates in the Institute certification programs and
              Institute certificants are required to adhere to the following
              principles:
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="5">
            <ul>
              <li>
                <v-icon>mdi-check</v-icon>
                Act in the best interest of the client.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Disclose services to be offered and provided, related charges,
                and compensation.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Disclose the existence of actual, potential, and/or perceived
                conflicts of interest and relevant financial relationships,
                direct and/or indirect. Take appropriate action to resolve or
                manage any such conflicts.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Provide clients information needed to make informed decisions.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Respond to client inquiries and instructions appropriately,
                promptly, completely, and truthfully.
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="5">
            <ul>
              <li>
                <v-icon>mdi-check</v-icon>
                Maintain confidentiality of client information, however
                acquired, consistent with legal and regulatory requirements and
                firm policies.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Provide competent service by truthful representation of
                competency, maintenance and/or development of professional
                capabilities, and, when appropriate, the recommendation of other
                professionals.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Comply with legal and regulatory requirements related to one's
                practice of his or her profession.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Maintain a high level of ethical conduct.
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </article>
  </div>
</template>

<script>
  import ContentMatrix from '@/components/ContentMatrix'
  import Statistic from '@/components/Statistic'
  import ResourceOther from '@/components/ResourceOther'

  export default {
    components: {
      ContentMatrix,
      Statistic,
      ResourceOther,
    },
    data() {
      return {}
    },

    computed: {
      resource() {
        // console.log('this.data.resources:', this.data.resources)
        return this.data.resources[this.$route.params.resourceSlug]
      },
    },
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .resource {
    margin-bottom: 5em;

    a {
      color: @text-color !important;
    }

    & + .disclaimer {
      display: none;
    }

    article {
      h2 {
        color: @brand-yellow;
        font-size: @font-size-h2;
        margin: 1em 0 0.25em;
      }

      .v-card {
        padding-bottom: 5em;

        & > .container > div:first-child {
          align-items: flex-end;
        }
      }

      .center-col,
      .left-col {
        h3 {
          color: @text-color;
          font-family: @font-family-base;
          font-size: @font-size-base;
          margin: 2em 0 0.5em;
        }

        h4 {
          color: @text-color;
        }

        hr {
          border-bottom: dotted @brand-dark-blue 1px;
          border-width: 0 0 1px 0;
          margin: 2em 0;
        }
      }

      .right-col {
        h4 {
          text-transform: uppercase;
          color: @brand-dark-gray;
          font-weight: 700;
          margin-bottom: 1em;
        }

        ul,
        li {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }

        li {
          margin-bottom: 0.25em;
        }

        ul {
          margin-bottom: 2em;
          padding-bottom: 2em;

          &:not(:last-child) {
            border-bottom: dotted @brand-dark-blue 1px;
          }
        }
      }

      .subtitle {
        font-family: @font-family-serif;
        font-size: @font-size-uphalf;
      }

      .summary {
        font-size: @font-size-uphalf;
        font-weight: 700;
        line-height: 1.083em;
      }

      h3 {
        text-transform: uppercase;
        font-size: @font-size-upbit;
        font-weight: 700;
        margin: 1em 0;
      }

      h4 {
        text-transform: none;
        font-size: @font-size-base;
        margin: 1em 0 0 0;
        line-height: 1.5em;
      }

      aside {
        h3 {
          color: @brand-deep-yellow;
        }

        h4 {
        }

        &.resources {
          background-color: rgba(112, 148, 170, 0.11);
          padding: 1em 1.5em 3em;
          position: relative;
          right: -12px;
        }
      }
    }

    .supplement {
      background-color: @brand-blue-gray;
      color: @brand-white;

      h3 {
        font-weight: 700;
        color: @brand-yellow;
        font-size: @font-size-h3;
        text-transform: none;
        text-align: center;
        margin: 1em 0 0.5em 0;

        & + p {
          color: @brand-white;
          font-family: @font-family-serif;
          text-align: center;
          max-width: 80%;
          margin: auto;
        }
      }

      p {
        font-family: @font-family-serif;
      }

      li {
        position: relative;
        list-style-type: none;
        margin-left: 1.5em;
        margin-bottom: 1em;
        line-height: 1.5em;

        .v-icon {
          position: absolute;
          background: @brand-yellow;
          border-radius: 50%;
          left: -1.5em;
        }
      }
    }
  }
</style>
