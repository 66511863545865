<template>
  <v-card class="advisor-search">
    <v-container>
      <h3>Look up an Advisor's IWI record</h3>
      <v-row>
        <v-col>
          <p><label>Search by Name:</label></p>
          <v-text-field
            autofocus
            outlined
            dense
            hide-details
            placeholder="Advisor's Full Name"
            @keyup.enter="goSearch()"
            v-model="search"
            required
            :rules="[
              () => !!search || 'This field is required',
              () =>
                search.length >= 3 ||
                'Full name must include characters for first and last names',
              () => !isNotValidName(search) || 'This name is invalid',
            ]"
          ></v-text-field>
        </v-col>
        <v-col>
          <p><label>Location</label> (optional):</p>
          <v-text-field
            outlined
            dense
            hide-details
            placeholder="ZIP Code"
            @keyup.enter="goSearch()"
            v-model="searchZip"
            :rules="[
              () =>
                searchZip.length >= 5 ||
                'ZIP Code must be 5 digits',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            primary
            class="highlight"
            @click="goSearch()"
            :disabled="isNotValidName(search) || search.length === 0"
          >
            Search
          </v-btn>
        </v-col>
        <v-col>
          <p class="note">To find a qualified advisor near you, <a href="/investor-preferences">enter your ZIP code</a> in the matching tool.</p>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  export default {
    name: 'AdvisorSearch',
    components: {},
    props: {},
    data() {
      return {
        search: '',
        searchZip: '',
      }
    },
    computed: {},
    methods: {
      goSearch() {
        this.$router.push(
          `/investor-matches?search=${this.search.trim()}&searchZip=${this.searchZip.trim()}`
        )
      },
      isNotValidName(name) {

        return (/[^A-Za-z\s'-]/.test(name) || name.length < 3)
      },
    },
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .advisor-search {
    background-color: white;
    padding: 1em;
    max-width: 370px;
    .v-input {
      max-width: 300px;
    }
    h3 {
      color: @brand-dark-blue !important;
      font-size: @font-size-upbit;
      margin-bottom: .5em;
    }

    p {
      margin: .5em 0;

      &.note {
        font-size: .75em;
      }
    }

    .v-btn {
      &.highlight {
        padding: 1.5em 2em !important;
      }
    }
  }
  @media screen and (min-width: 600px) {
    .advisor-search {
      max-width: unset;
      .v-input {
        max-width: unset;
      }
    }
  }
</style>
