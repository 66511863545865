<template>
  <fragment>
    <h4>About IWI Certifications</h4>
    <ul>
      <template v-for="cert in Object.values(data.certifications)">
        <li v-if="cert.slug != pageSlug" :key="cert.id">
          <router-link :to="'/' + cert.uri">{{ cert.acronym }}</router-link>
        </li>
      </template>
    </ul>
  </fragment>
</template>

<script>
  import { Fragment } from 'vue-fragment'

  export default {
    name: 'CertsList',
    components: { Fragment },
    props: {},
    data() {
      return {
        pageSlug: '',
      }
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {
      this.pageSlug = this.$route.fullPath.split('/')[2]
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
</style>
