<template>
  <div id="inspiration-wrap" :style="`background-image: url('${url}');`">
    <div class="gradient">
      <v-container class="inspiration" :key="inspiration.id">
        <h5>
          {{ inspiration.inspirationalFirstWord }}
          <em>{{ inspiration.title }}</em>
          {{ deserve }} a certified and exceptional advisor
        </h5>
        <h6>
          Choose an advisor that has one of the industry’s leading
          certifications
        </h6>
      </v-container>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Inspiration',
    components: {},
    data() {
      return {
        inspiration: {},
        url: '',
        deserve: '',
      }
    },
    computed: {},
    methods: {
      setInspiration() {
        const inspiration = this.data.inspiration
        const index = Math.floor(Math.random() * inspiration.length)

        this.inspiration = inspiration[index]
        this.id = inspiration[index].id
        this.url = inspiration[index].inspirationalImage[0].url
        this.deserve =
          inspiration[index].title.slice(-1) == 's' ? 'deserve' : 'deserves'
      },
    },
    watch: {
      // do things after route changes
      $route(to, from) {
        this.setInspiration()
      },
    },
    mounted() {
      this.setInspiration()
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  #inspiration-wrap {
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    align-items: center;
    min-height: 720px;
    color: @brand-dark-gray;
    text-align: center;

    .gradient {
      padding-top: 1.5em;
      width: 100%;
      min-height: 720px;
      background-position: top;
      position: relative;
      display: inline-block;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.9) 20%,
        rgba(255, 255, 255, 0.2) 35%,
        rgba(255, 255, 255, 0) 50%
      );
    }

    h5 {
      font-size: @font-size-h4;
      font-weight: 700;
      line-height: 1.167;
      font-family: @font-family-serif;
      margin-bottom: 0.5em;

      em {
        text-transform: uppercase;
        color: @brand-deep-yellow;
        font-style: normal;
      }
    }

    h6 {
      font-size: @font-size-base;
      line-height: 1.25;
      font-family: @font-family-base;
    }

    @media screen and (min-width: 960px) {
      .gradient {
        padding-top: 3em;
      }

      h5 {
        font-size: @font-size-h2;
        font-weight: 700;
        line-height: 1.85;
        margin-bottom: 0;
      }

      h6 {
        font-size: @font-size-sh1;
        line-height: 1.85;
      }
    }
  }
</style>
