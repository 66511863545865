<template>
  <div class="investor-profile">
    <form @submit.prevent="save">
      <span class="form-title">Premium Listing (More About You)</span>
      <div class="form-body" v-if="validIMCAToken && !loading">
        <section class="textarea">
          <label for="intro">
            Introduce yourself, including personal interests, activities, and
            what differentiates you from other advisors:
          </label>
          <textarea name="intro" v-model="advisorProfile.intro"></textarea>
        </section>
        <section>
          <div class="section-title">
            <span>Awards &amp; Distinctions</span>
            <div>
              <span>{{ iconsLength }}/{{ maxIcons }}</span>
              <input
                :disabled="iconsLength >= maxIcons"
                type="button"
                class="add"
                value="add"
                @click="$store.dispatch('addIcon')"
              />
            </div>
          </div>
          <div class="fields" v-for="icon in icons" :key="`award_${icon.id}`">
            <template v-if="!icon.deleted">
              <div>
                <label for="awardTitle">Alt Title (required):</label>
                <input name="awardTitle" v-model="icon.title" required />
              </div>
              <div>
                <label>
                  <input
                    type="button"
                    value="replace"
                    @click="resetImage(icon)"
                    v-show="icon.src"
                  />
                </label>

                <div class="upload-wrap">
                  <!--UPLOAD-->

                  <div class="dropbox" v-show="!icon.src">
                    <input
                      type="file"
                      name="icons"
                      :disabled="isSaving"
                      @change="fileChange($event, icon, 'icons')"
                      accept="image/*"
                      class="input-file"
                      :required="icon.src ? false : true"
                    />
                    <p>
                      Drag your icon file here
                      <br />
                      or click to browse
                    </p>
                  </div>
                  <!--SUCCESS-->

                  <div v-show="icon.src">
                    <img
                      :src="icon.src"
                      :srcSet="fullSrc('icons', icon)"
                      class="img-thumbnail"
                      :alt="icon.title"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label for="iconLink">Link Target URL:</label>
                <input
                  name="iconLink"
                  v-model="icon.link"
                  pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                />
              </div>
              <input
                type="button"
                value="delete"
                class="delete"
                @click="icon.deleted = true"
              />

              <hr />
            </template>
          </div>
        </section>
        <section>
          <div class="section-title">
            <span>Additional Photos</span>
            <div>
              <span>{{ photosLength }}/{{ maxPhotos }}</span>
              <input
                :disabled="photosLength >= maxPhotos"
                type="button"
                class="add"
                value="add"
                @click="$store.dispatch('addPhoto')"
              />
            </div>
          </div>
          <div
            class="fields"
            v-for="photo in photos"
            :key="`photo_${photo.id}`"
          >
            <template v-if="!photo.deleted">
              <div>
                <label>
                  <input
                    type="button"
                    value="replace"
                    @click="resetImage(photo)"
                    v-show="photo.src"
                  />
                </label>

                <div class="upload-wrap">
                  <!--UPLOAD-->

                  <div class="dropbox" v-show="!photo.src">
                    <input
                      type="file"
                      name="photos"
                      :disabled="isSaving"
                      @change="fileChange($event, photo, 'photos')"
                      accept="image/*"
                      class="input-file"
                      :required="photo.src ? false : true"
                    />
                    <p>
                      Drag your image file here
                      <br />
                      or click to browse
                    </p>
                  </div>
                  <!--SUCCESS-->

                  <div v-show="photo.src">
                    <img
                      :src="photo.src"
                      :srcSet="fullSrc('photos', photo)"
                      class="img-thumbnail"
                      :alt="photo.caption"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label for="photoCaption">Caption (required):</label>
                <input name="photoCaption" v-model="photo.caption" required />
              </div>
              <input
                type="button"
                value="delete"
                class="delete"
                @click="photo.deleted = true"
              />

              <hr />
            </template>
          </div>
        </section>
        <section>
          <div class="section-title">
            <span>Profile Videos</span>
            <div>
              <span>{{ videosLength }}/{{ maxVideos }}</span>
              <input
                :disabled="videosLength >= maxVideos"
                type="button"
                class="add"
                value="add"
                @click="$store.dispatch('addVideo')"
              />
            </div>
          </div>
          <div
            class="fields"
            v-for="video in videos"
            :key="`video_${video.id}`"
          >
            <template v-if="!video.deleted">
              <div>
                <label for="videoLink">URL (required):</label>
                <input
                  placeholder="Valid Youtube or Vimeo link only"
                  name="videoLink"
                  v-model="video.link"
                  title="Only a valid Youtube or Vimeo link please"
                  required
                  pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                />
              </div>
              <div>
                <label for="videoCaption">Caption (required):</label>
                <input name="videoCaption" v-model="video.caption" required />
              </div>
              <input
                type="button"
                value="delete"
                class="delete"
                @click="video.deleted = true"
              />

              <hr />
            </template>
          </div>
        </section>
        <section class="form-buttons">
          <input
            type="submit"
            :disabled="isSaving"
            :value="isSaving ? 'Please Wait...' : 'Save'"
          />
          <input
            type="button"
            value="Cancel"
            :disabled="isSaving"
            @click="cancel()"
          />
        </section>
      </div>
      <div class="warning" v-else-if="!loading">
        <h5>
          You are not logged in.
          <br />
          Please close this window and try again.
        </h5>
      </div>
      <div class="notice" v-else>
        <h5>Loading...</h5>
      </div>
    </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import { mapMultiRowFields } from 'vuex-map-fields'
  import { v4 as uuidv4 } from 'uuid'

  // Set axios to always use credentials
  axios.defaults.withCredentials = true

  export default {
    components: {},
    data() {
      return {
        formData: new FormData(),
        isSaving: false,
        IMCAToken: this.$route.query.IMCAToken,
        validIMCAToken: true,
        loading: true,
        maxIcons: 10,
        maxPhotos: 4,
        maxVideos: 4,
      }
    },
    computed: {
      ...mapMultiRowFields([
        'advisorProfile.icons',
        'advisorProfile.photos',
        'advisorProfile.videos',
      ]),
      iconsLength() {
        return this.checkDeletedLength(this.icons)
      },
      photosLength() {
        return this.checkDeletedLength(this.photos)
      },
      videosLength() {
        return this.checkDeletedLength(this.videos)
      },
    },
    methods: {
      getAdvisorProfile() {
        if (!this.IMCAToken) this.validIMCAToken = false
        else {
          axios({
            method: 'get',
            url: '/napi/user-from-token?IMCAToken=' + this.IMCAToken,
          })
            .then((response) => {
              if (response.data.success) {
                const advisor = response.data.data
                this.advisorProfile.intro = advisor.intro
                this.advisorProfile.icons = advisor.icons
                this.advisorProfile.photos = advisor.photos
                this.advisorProfile.videos = advisor.videos

                this.validIMCAToken = response.data.success
                this.loading = false
                // console.log(response.data)
                // console.log(this.advisorProfile)
              } else {
                this.validIMCAToken = false
                this.loading = false
              }
            })
            .catch((error) => {
              this.validIMCAToken = false
              this.loading = false
              console.log('err', error)
            })
        }
      },
      checkDeletedLength(entries) {
        let count = 0
        entries.forEach((entry) => {
          if (!entry.deleted) count++
        })
        return count
      },
      resetImage(entry) {
        // reset image file input to allow for change event if same image is added
        entry.src = entry.file_uuid = ''
        if (entry.fileTarget) entry.fileTarget.value = null
      },

      deleteArrayIndex(array, index) {
        array.splice(index, 1)
        // console.log(array)
      },

      fileChange(event, entry, fieldName) {
        // set file target reference for future clearing
        entry.fileTarget = event.target

        // Set file from files array and set imgThumb
        const [file] = event.target.files
        const path = event.path || (event.composedPath && event.composedPath())
        const imgThumb = path[2].childNodes[1].childNodes[0]

        // handle file changes
        if (!file) return

        // create UUID for file
        entry.file_uuid = uuidv4()

        // save file using unique UUID for file name
        this.formData.append(fieldName, file, entry.file_uuid)

        // Clear previous src and udpate entry and imgThumb to new src
        entry.src = ''
        entry.src = imgThumb.src = URL.createObjectURL(file)

        entry.uploadAgain = false
        entry.iconUpdated = true
      },
      fullSrc(type, entry) {
        // src will match file_uuid when first pulled from Hasura so udpate the URL here
        if (entry.file_uuid == entry.src && entry.src != '') {
          entry.src = `${this.SPACES_ENDPOINT}/${type}/${entry.file_uuid}`
          return true
        }
        return ''
      },
      save() {
        this.isSaving = true
        // add advisorProfile to formData
        this.formData.set('advisorProfile', JSON.stringify(this.advisorProfile))

        // console.log('formData2:', this.formData)

        // for (var key of this.formData.entries()) {
        //   console.log(key[0] + ', ' + key[1])
        // }

        axios({
          method: 'post',
          url: '/napi/update-advisor-profile?IMCAToken=' + this.IMCAToken,
          data: this.formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            // console.log('Profile Updated')
            // console.log(response.data)
            this.isSaving = false

            // close the window
            close()
          })
          .catch((error) => {
            console.log('err', error)
          })
      },
      cancel() {
        // if the user cancels, just close the window
        close()
      },
    },
    created() {
      // console.log('created')
      this.getAdvisorProfile()
    },
  }
</script>

<style lang="less">
  .investor-profile {
    position: relative;
    min-width: 100%;
    min-height: 100%;
    background-color: #f3f3f3 !important;

    form {
      width: 450px;
    }

    p,
    span,
    div,
    label {
      font-family: Roboto, sans-serif;
      font-size: 13px;
      line-height: 13px;
    }

    span.form-title {
      display: block;
      width: 100%;
      height: 23px !important;

      padding: 5px 0 0 5px !important;
      text-indent: 2px;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 2px !important;

      background-color: #7a99ac;
    }

    input,
    textarea {
      padding: 1px;
      box-sizing: border-box;
      background-color: #fff;

      border-style: inset;
      border-width: 1px;
      border-radius: 3px;
      margin-top: 5px;
    }

    input[type='button'],
    input[type='submit'] {
      width: auto;
      padding: 4px;
      text-transform: uppercase;

      background-color: rgb(239, 239, 239);

      &:hover {
        background-color: rgb(229, 229, 229);
      }

      &:active {
        background-color: rgb(245, 245, 245);
      }

      &.add {
        float: right;
      }

      &:disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
      }
    }

    input {
      width: 70%;
    }

    textarea {
      width: 100%;
      height: 50px;
    }

    .warning,
    .notice {
      padding: 20px;
      text-align: center;
    }

    .form-body {
      margin-top: 15px;
      padding: 0 15px;

      & > section {
        display: block;
        align-items: center;
        margin: 10px 0 30px;

        justify-content: space-between;
        align-items: center;

        label {
          width: 30%;
          text-align: right;
          padding-right: 15px;
        }
        &.textarea {
          flex-direction: column;

          label {
            text-align: left;
            width: 100%;
          }
        }

        &.form-buttons {
          display: flex;
          justify-content: center;

          & > input[type='button'],
          input[type='submit'] {
            text-transform: none;
          }
        }

        .section-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: bold;
          margin-bottom: 10px;

          & > div {
            display: flex;
            justify-content: center;
            align-items: center;

            & > input {
              margin-left: 10px;
            }
          }
        }

        .fields {
          display: block;
          text-align: right;
          & > div {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          & > hr {
            margin: 15px 0 15px 0;
          }

          &:last-child hr {
            display: none;
          }
        }
      }
    }

    // File upload area

    .upload-wrap {
      margin-top: 5px;
      width: 70%;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      .dropbox {
        display: flex;
        justify-content: center;
        align-items: center;
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -5px;
        color: dimgray;
        height: 100px;
        position: relative;
        cursor: pointer;
      }

      .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100px;
        position: absolute;
        cursor: pointer;
      }

      .img-thumbnail {
        max-height: 150px;
        max-width: 200px;
      }

      .dropbox:hover {
        background: lightgray; /* when mouse over to the drop zone, change color */
      }

      .dropbox p {
        font-size: 1.2em;
        text-align: center;
      }
    }
  }
</style>
