<template>
  <v-container class="below-header priorities">
    <h2>What are your priorities?</h2>
    <v-card
      v-scroll.self="onScroll"
      :class="[$vuetify.breakpoint.smAndDown ? 'overflow-y-auto' : 'card']"
      max-height="250"
      color="transparent"
      elevation="0"
      id="scroll-card"
    >
      <h3 class="question">
        Tell us what else are important factors to your investing.
      </h3>
      <p class="directions">Choose as many as you need or find important.</p>

      <v-btn-toggle
        multiple
        v-model="user.profile.importantFactors"
        @change="$store.dispatch('updateInvestorProfile')"
        :value-comparator="simpleMatch"
      >
        <div class="button-group">
          <toggle-button
            v-for="factor in data.importantFactors"
            :key="factor.id"
            :value="factor.id"
            :phrase="factor.title"
            :definition="factor.definition"
            :icon="`/img/icons/icon-${factor.slug}.svg`"
            standalone
          ></toggle-button>
        </div>
      </v-btn-toggle>
      <h3 class="question">
        I would like an advisor certified with one of these core competencies:
      </h3>
      <p class="directions">
        If you don't choose any, we will look across all certificants for a
        great match.
      </p>

      <v-btn-toggle
        v-model="user.profile.advisorCertificate"
        @change="$store.dispatch('updateInvestorProfile')"
        :value-comparator="simpleMatch"
        multiple
      >
        <div class="button-group">
          <toggle-button
            v-for="competency in data.advisorCoreCompetencies"
            :key="competency.id"
            :value="competency.id"
            :phrase="competency.title"
            :definition="competency.definition"
            standalone
          ></toggle-button>
        </div>
      </v-btn-toggle>
    </v-card>

    <match-navigation :navProgress="progressIndex"></match-navigation>
  </v-container>
</template>

<script>
  import MatchNavigation from '@/components/MatchNavigation'
  import ToggleButton from '@/components/ToggleButton'

  export default {
    name: 'InvestorPriorities',
    components: { MatchNavigation, ToggleButton },
    data() {
      return {}
    },
    computed: {
      progressIndex() {
        const index = this.data.settings.matchingPageOrder.findIndex(x => x.stepSlug === 'investor-priorities')
        return index+1
      }
    },
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
  .priorities {
    .card {
      margin-bottom: 25rem;
    }
  }
  @media (max-width: 599px) {
    .priorities {
      h3.question {
        margin: 1rem 0;
      }
      h2 {
        margin-top: -1.3rem;
      }
      .button-group {
        text-align: center;
        margin: auto;
      }
      .v-btn__content {
        padding: 0 10%;
      }
    }
  }
</style>
