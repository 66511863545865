import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#0D6587',
        secondary: '#E35007',
        accent: '#C6EE56',
        error: '#F15025',
        info: '#2196F3',
        success: '#85bf42',
        warning: '#E35007',
        darkgray: '#3D3D3D',
        focus: '#C6EE56',
      },
    },
  },
})
