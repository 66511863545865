<template>
  <div id="advisor-landing">
    <h2 class="container">For Exceptional Advisors</h2>

    <v-container>
      <v-container class="panel">
        <v-row class="supplement intro">
          <v-col>
            <p class="pitch">
              Get Matched to
              <span>INTERESTED</span>
              investors
            </p>

            <p>
              Take advantage of the benefits of Investments &amp; Wealth
              Institute membership and update your profile on our best-in-class
              advisor search tool.
            </p>

            <p>
              Grow your business by matching with interested investors who are
              looking for advisors with your skills and knowledge.
            </p>

            <v-btn class="highlight">
              <a
                href="https://investmentsandwealth.org/membership-overview"
                target="_blank"
              >
                Learn about the
                <br />
                benefits of membership
              </a>
            </v-btn>
          </v-col>
          <v-col>
            <img
              src="/img/mockup-ipad-phone-cropped-new.png"
              alt=""
              class="mockup"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="justify-center">
            <h3 class="header-h3">
              Market your business. Stand out from the competition. Generate
              leads.
            </h3>
            <p class="subtitle">
              An Investments &amp; Wealth Institute membership unlocks your
              access to investor leads and more.
            </p>
          </v-col>
        </v-row>
        <v-row class="small-screen">
          <v-col class="justify-center">
            <svg-img svgUrl="/img/graphic-notes.svg"></svg-img>
            <h4>Professional Profiles</h4>
            <p>
              Your profile is a great way to market your business, highlight
              your advanced certifications and engage interested investors. Give
              potential clients a look at who you are, your skills and services,
              your mission and values by sharing photos, videos, accolades and
              more.
            </p>
          </v-col>
          <v-col class="justify-center">
            <svg-img svgUrl="/img/graphic-voicebubbles.svg"></svg-img>
            <h4>Spot-on Match Making</h4>
            <p>
              We match you to investors who meet your ideal client profile. With
              criteria like investable income, life stage, investing goals and
              more. This kind of matchmaking puts you in the driver’s seat and
              helps you increase your assets under management and grow your
              client base.
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="justify-center d-flex flex-column align-center">
            <v-btn class="highlight">
              <a
                href="https://investmentsandwealth.org/membership-overview"
                target="_blank"
              >
                Learn about the
                <br />
                benefits of membership
              </a>
            </v-btn>

            <hr class="dashed-divider" />

            <h3>Advisor profiles that inform and engage</h3>
            <p class="subtitle">
              Profiles include certification(s), services, photos, video and
              more
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs v-model="tabs" class="headers">
              <v-tab :transition="false" key="1">
                Premium membership Profile
              </v-tab>
              <div class="seperator" v-if="$vuetify.breakpoint.smAndUp"></div>
              <v-tab :transition="false" key="2">
                Basic Membership Profile
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabs" class="annotations">
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col md="7" v-if="$vuetify.breakpoint.smAndUp">
                      <img
                        src="/img/premium-profile-mockup-new.png"
                        alt="annotated image of a premium &amp; Signature member profile page"
                      />
                    </v-col>
                    <v-col md="4" class="right-column">
                      <h4>Premium Membership Profile Benefits</h4>
                      <ol>
                        <li>
                          <h4>
                            Your name, your investment & Wealth Institute
                            certifications and an ability for investors to save
                            your profile
                          </h4>
                        </li>
                        <li><h4>Profile photo</h4></li>
                        <li>
                          <h4>
                            Your Investments &amp; Wealth Institute
                            certifications and other self-reported designations
                          </h4>
                        </li>
                        <li>
                          <h4>
                            Your matching criteria with the interested investor
                          </h4>
                        </li>
                        <li>
                          <h4>
                            Your firm location and a statement about your firm
                          </h4>
                        </li>
                        <li>
                          <h4>Your key matches with the interested investor</h4>
                        </li>
                        <li>
                          <h4>
                            Your Investments &amp; Wealth Institute certified
                            and self-reported services
                          </h4>
                        </li>
                        <li><h4>Other accolades and accomplishments</h4></li>
                        <li><h4>Your photos, videos and other media</h4></li>
                        <li>
                          <h4>
                            Your contact information and social media links
                          </h4>
                        </li>
                        <li>
                          <h4>
                            A contact form to connect you with interested
                            investors
                          </h4>
                        </li>
                        <!-- <li>
                          <h4>A personal statement</h4>
                          <p>
                            Your elevator pitch to share why you are the right
                            advisor
                          </p>
                        </li>
                        <li>
                          <h4>Additional photos</h4>
                          <p>
                            Share more than just a profile photo to give
                            interested investors a deeper look inside your
                            business
                          </p>
                        </li>
                        <li>
                          <h4>Embedded videos</h4>
                          <p>
                            Videos boost conversion rates, so be sure to share
                            marketing and promotional videos about your business
                          </p>
                        </li>
                        <li>
                          <h4>Accolades and accomplishments</h4>
                          <p>
                            Share awards and other honors that help you stand
                            out in a crowd
                          </p>
                        </li>
                        <li><h4>Premium member label and badging</h4></li>
                        <li>
                          <h4>Priority placement in matching results</h4>
                          <p>
                            Signature and Elite member profiles are placed at
                            the top of search results, allowing interested
                            investors to see you first
                          </p>
                        </li> -->
                      </ol>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col md="7" v-if="$vuetify.breakpoint.smAndUp">
                      <img
                        src="/img/basic-profile-mockup.png"
                        alt="annotated image of a basic member profile page"
                      />
                    </v-col>
                    <v-col md="4" class="right-column">
                      <h4>Basic Membership Profile Benefits</h4>
                      <ol>
                        <li>
                          <h4>
                            Your name, your Investments & Wealth Institute
                            certifications, and an ability for investors to save
                            your profile
                          </h4>
                        </li>
                        <li><h4>Profile photo</h4></li>
                        <li>
                          <h4>
                            Your Investments & Wealth Institute certifications
                            and other self-reported designations
                          </h4>
                        </li>
                        <li>
                          <h4>
                            Your matching criteria with the interested investor
                          </h4>
                        </li>
                        <li>
                          <h4>
                            Your firm location and a statement about your firm
                          </h4>
                        </li>
                        <li>
                          <h4>Your key matches with the interested investor</h4>
                        </li>
                        <li>
                          <h4>
                            Your Investments & Wealth Institute certified and
                            self-reported services
                          </h4>
                        </li>
                        <li><h4>Your contact information</h4></li>
                        <li>
                          <h4>
                            A contact form to connect you with interested
                            investors
                          </h4>
                        </li>
                      </ol>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="space-between">
        <v-btn class="highlight">
          <a
            href="https://investmentsandwealth.org/membership-overview"
            target="_blank"
          >
            Learn about the
            <br />
            benefits of membership
          </a>
        </v-btn>

        <hr class="dashed-divider" />
      </v-container>

      <div class="panel supplement">
        <v-container>
          <v-row>
            <v-col class="justify-center">
              <h3>Membership Levels &amp; Benefits</h3>
              <p>
                The Investments &amp; Wealth Institute offers world-class
                resources available to members
              </p>
            </v-col>
          </v-row>
          <v-row justify="center" class="memberships">
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <h4>Basic Member</h4>
              <ul>
                <li>
                  <v-icon>mdi-check</v-icon>
                  Basic Profile
                </li>
                <li>
                  <v-icon>mdi-check</v-icon>
                  One Photo
                </li>
                <li>
                  <v-icon>mdi-check</v-icon>
                  Reporting
                </li>
              </ul>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <h4>Signature &amp; Elite</h4>
              <ul>
                <li>
                  <v-icon>mdi-check</v-icon>
                  Enhanced Profile
                </li>
                <li>
                  <v-icon>mdi-check</v-icon>
                  Accolades
                </li>
                <li>
                  <v-icon>mdi-check</v-icon>
                  Multiple Photos
                </li>
                <li>
                  <v-icon>mdi-check</v-icon>
                  Reporting
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="justify-center">
              <p>
                <a href="https://investmentsandwealth.org/membership-overview">
                  Learn more about the benefits of Investment &Wealth membership
                  levels
                </a>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>
  </div>
</template>

<script>
  import SvgImg from '@/components/SvgImg'

  export default {
    components: {},
    data() {
      return {
        tabs: null,
      }
    },

    computed: {},

    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  #advisor-landing {
    h2.container {
      margin: 4em auto -1.5em !important;
    }
    h3 {
      margin-bottom: 0.5em;
    }
    & + .disclaimer {
      display: none;
    }
    .small-screen {
      max-width: 300px;
      margin: -3em auto -7em auto;
    }
    .headers {
      margin-top: -3em;
      .v-slide-group__prev {
        display: none;
      }
      .v-tabs-bar {
        height: 5em;
      }
      .v-tab {
        font-weight: 900;
        max-width: 9.5rem;
        text-transform: capitalize;
      }
      .seperator {
        height: 27px;
        margin: 0 1em;
        border-left: solid black 2px;
      }
      .v-slide-group__content {
        margin-bottom: 0.5em;
        transform: none;
      }
      .v-tab--active {
        color: black;
        // text-transform: uppercase;
      }
      .v-tabs-slider {
        max-width: 260px;
        color: @brand-yellow;
        align-items: flex-start;
        margin: auto;
        &:nth-child(2) {
          padding-left: 2em;
        }
        // &:nth-child(1) {
        //   padding-left: -1em;
        // }
        &:after {
          content: '';
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid @brand-yellow;
          border-top: 10px solid transparent;
          display: inline-block;
          position: absolute;
          left: 50%;
          margin-left: -0.5em;
          transform: rotate(180deg);
          box-shadow: rgb(0 0 0 / 50%) 0 0 8px -5px;
        }
      }
    }
    .v-btn {
      max-width: 300px;
      &.highlight {
        padding-left: 4em;
        padding-right: 4em;
        z-index: 1;
        margin: 2em 0;
        font-size: 1rem;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
    }

    .space-between {
      background: transparent;
      text-align: center;
    }

    .dashed-divider {
      z-index: 0;
      border-top: dashed @brand-white 1px;
      width: 100%;
      margin-top: -3.6em;
      margin-bottom: 4.6em;
      background: transparent;
    }

    .justify-center {
      text-align: center;

      h4,
      ul {
        text-align: left;
      }

      &:not(.supplement *) {
        h3,
        h4,
        p {
          text-align: center;
        }
        .header-h3,
        .header-h3 + .subtitle {
          text-align: left;
        }

        h4 {
          font-size: @font-size-base;
          font-weight: 700;
          color: @brand-dark-gray;
          padding: 1.5em 0;
          margin: 0 1em;
          border-top: dashed 1px @brand-dark-blue;
        }

        & > .v-btn {
          margin: 2em 0 3em;
        }

        .dashed-divider {
          border-color: @brand-dark-blue;
          margin-top: -4.6em;
          margin-bottom: 4.6em;
        }

        svg {
          margin-bottom: 1.5em;
        }
      }
    }

    .annotations {
      flex-wrap: wrap;
      .row {
        padding: 0;
      }
      .v-btn {
        margin-top: 2.5em;
        max-width: 280px;
        font-size: 13.5px;
        box-shadow: 0px 0px 9px 0.5px rgb(168 168 168 / 50%);
      }
      .right-column {
        margin-left: 0em;
        ol {
          padding-left: 0.4rem;
        }
      }
      .button-white {
        margin: 2.5em auto 0;
        background-color: white;
      }
    }

    .supplement {
      background-color: @brand-blue-gray;
      .memberships {
        margin: -2em 0;
      }

      h3 {
        color: @brand-yellow;
      }

      h4 {
        color: @brand-white;
        font-family: @font-family-serif;
        text-transform: none;
        margin-bottom: 1em;
      }

      p,
      li {
        color: @brand-white;

        &.pitch {
          font-family: @font-family-serif;
          font-size: @font-size-double;
          line-height: 1.2em;
          font-weight: 800;

          span {
            color: @brand-yellow;
          }
        }

        & + p + .v-btn {
          margin-top: 3em;
        }
      }

      &.intro {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        position: relative;
      }

      .row {
        padding: 1em;
      }

      .justify-center {
        & > div {
          border-bottom: dashed white 1px;
          padding-left: 1em;
          padding-top: 0;
          margin-bottom: 1em;

          &:first-child {
            padding-left: 1em;
          }

          &:last-child {
            border-width: 0;
          }
        }
        a {
          color: white;
        }
      }

      .mockup {
        display: none;
      }
    }

    .row {
      padding: 2em 1em;
    }

    .v-tabs-items {
      h4 {
        color: @brand-dark-blue;
        text-transform: none;
        font-size: @font-size-upbit;
        letter-spacing: 0;
        margin-bottom: 1em;
      }

      ol {
        counter-reset: li-count;

        li {
          counter-increment: li-count;
          list-style-type: none;
          position: relative;
          margin-left: 3em;
          min-height: 3.5em;
          padding-bottom: 0.25em;
          line-height: 1.25em;

          &:before {
            content: counter(li-count);
            position: absolute;
            top: 0;
            left: -3em;
            font-size: @font-size-upbit;
            font-weight: 700;
            font-family: @font-family-serif;
            color: @brand-dark-gray;
            background: @brand-yellow;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2em;
            width: 2em;
            line-height: 1em;
            border-radius: 50%;
          }

          h4 {
            font-size: @font-size-base;
            margin-bottom: 0;
            line-height: 1.25em;
          }

          p {
            font-size: @font-size-mini;
          }
        }
      }
    }

    ul {
      li {
        position: relative;
        list-style-type: none;
        margin-left: 1.5em;
        margin-bottom: 1em;
        line-height: 1.5em;

        .v-icon {
          position: absolute;
          background: @brand-yellow;
          border-radius: 50%;
          left: -1.5em;
        }
      }
    }

    @media screen and (min-width: 600px) {
      h2.container {
        margin: 3em auto 0.5em !important;
      }
      .justify-center {
        & > div {
          padding-left: 3em !important;
          border-right: dashed white 1px;
          border-bottom: dashed white 0px !important;
          margin-bottom: 0em;
        }
      }
      .v-slide-group__content {
        margin-bottom: 1em;
      }
      .right-column {
        margin-left: 2em;
      }
      .small-screen {
        max-width: none;
        margin: unset;
      }
      .supplement {
        .mockup {
          display: block;
          position: absolute;
          bottom: 0;
          right: -5em;
          height: 22em;
          width: auto;
        }
      }
      .v-btn {
        max-width: 400px;
      }
      .headers {
        .v-slide-group__prev {
          display: block;
        }
        .v-tab {
          max-width: 20rem !important;
        }
        .v-tabs-bar {
          height: 2.2em;
        }
      }
    }

    @media screen and (min-width: 960px) {
      h2 {
        max-width: 900px;
      }

      .supplement {
        .mockup {
          display: block;
          right: 1em;
          height: 32em;
        }
      }
      .col-md-4 {
        max-width: 37.3%;
      }
    }

    @media screen and (min-width: 1264px) {
      h2 {
        max-width: 1185px;
      }

      .supplement {
        .mockup {
          display: block;
          right: 5em;
          height: 34em;
        }
      }
    }

    @media screen and (min-width: 1904px) {
      h2 {
        max-width: 1785px;
      }

      .supplement {
        .mockup {
          display: block;
          right: 14em;
          height: 30em;
        }
      }
      .col-md-4 {
        max-width: 37.3%;
      }
      .annotations {
        .right-column {
          margin-left: -10em;
        }
      }
    }
  }
</style>
