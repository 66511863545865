// Used to pull in VueX store globally along with other computed elements

// VUEX STORE
import { mapGetters, mapState } from 'vuex'

// OTHER GLOBAL LIBRARIES
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      SPACES_ENDPOINT: 'https://iwi.superhumane.net',
      RULES: {
        required: [(v) => !!v || 'This is required'],
        email: [(v) => /.+@.+/.test(v) || 'E-mail must be valid'],
        phone: [
          (v) =>
            /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(v) ||
            'Phone number must be in format: XXX-XXX-XXXX',
        ],
      },
      RESOURCE_COUNT_PER_PAGE: 4,
      scrollInvoked: 0,
    }
  },
  computed: {
    // Include Vuex getters globally
    ...mapGetters([
      'dataLoading',
      // 'loginLoading',
      // 'loggedIn',
      'data',
      'appContent',
    ]),
    ...mapState(['advisorProfile', 'user', 'appState', 'calculated']),

    // Other global computed vars
    is() {
      let criteria = new Set()

      // Is touch device
      if (typeof window.ontouchstart !== 'undefined') {
        criteria.add('touchDevice')
      }

      // Debug mode enabled
      // if (this.data.settings.debugMode) {
      //   criteria.add('debugMode')
      // }

      //console.log(criteria)
      return criteria
    },
    nearbyObject() {
      return {
        description: 'Location Nearby',
        id: '00000000000',
        netforumValues: '',
        slug: 'nearby',
        // title: 'Preferred Attributes - Nearby ' + this.user.postal_code,
        title: 'Nearby: ' + this.user.postal_code,
        type: 'preferredAttributes',
      }
    },
    nearbyScore() {
      return this.data.settings.nearbyScore
    },
    aboutMe() {
      let aboutMe = []
      let amObj = {}
      let title = ''

      for (const [key, value] of Object.entries(this.user.profile)) {
        // get icon based on investor entry using slug
        // about me currently includes: lifeEvents, importantFactors & preferredAttributes
        if (
          ![
            'lifeStageId',
            'lifeStages',
            'timing',
            'householdIncome',
            'investableWealth',
            'advisorCertificate',
          ].includes(key)
        ) {
          // force dataArray to array in case of single item
          let valueArray = value
          if (!Array.isArray(value)) valueArray = [value]
          // loop through all child elements
          for (const id of valueArray) {
            // console.log(id)
            title = id == '00000000000' ? 'Nearby' : this.data.profile[id].title

            // if nearby attribute selected
            if (id == '00000000000') {
              amObj = this.nearbyObject
            }
            // otherwise parse all other attributes
            else {
              amObj = {
                ...this.data.profile[id],
                ...{
                  type: key,
                  title:
                    // this.toTitle(key) + ' - ' + this.data.profile[id].title,
                    this.data.profile[id].title,
                },
              }
            }
            // console.log(amObj)

            aboutMe.push(amObj)
          }
        }
        // for these types, change slug to generic type icon instead of entry specific icon
        else if (['householdIncome', 'investableWealth'].includes(key)) {
          // check value to make sure user has selected
          if (value) {
            amObj = {
              ...this.data.profile[value],
              ...{
                type: key,
                slug: this.toKebab(key),
                title: this.toTitle(key) + ' ' + this.data.profile[value].title,
              },
            }
            // console.log(amObj)

            aboutMe.push(amObj)
          }
        }
        // special case for life stages
        else if (['lifeStages'].includes(key)) {
          // console.log('LIFESTAGE_VALUE:', value)
          // check value to make sure user has selected
          if (value) {
            // map to Craft life stages category IDs
            const lifeStageIDs = ['684', '685', '686', '687']
            let id = lifeStageIDs[Math.min(Math.floor(value / 100), 4)]
            amObj = {
              ...this.data.profile[id],
              ...{
                type: key,
                title: 'Life Stage: ' + this.data.profile[id].title,
              },
            }
            // console.log(amObj)

            aboutMe.push(amObj)
          }
        }
      }

      // sort about me before return
      const aboutMeOrderByType = [
        'lifeStages',
        'householdIncome',
        'investableWealth',
        'preferredAttributes',
        'lifeEvents',
        'importantFactors',
      ]
      // map over aboutMeOrder, find it in aboutMe and return
      const aboutMeOrdered = [].concat(
        ...aboutMeOrderByType.map((type) =>
          aboutMe.filter((attrib) => attrib.type === type)
        )
      )

      return aboutMeOrdered
    },
    filteredResources() {
      let resourcesFound = 0

      let filteredResources = Object.values(this.data.resources).filter(
        (resource) => {
          // build searchable string from all resource components
          let searchString =
            resource.title +
            resource.subline +
            resource.description +
            resource.summary
          // loop through component columns and build up string
          for (const col of ['leftColComp', 'centerColComp']) {
            if (resource[col]) {
              for (const comp of resource[col]) {
                if (comp.text) searchString += comp.text
                if (comp.items) {
                  for (const item of comp.items) {
                    searchString += item.item
                  }
                }
              }
            }
          }
          // build regex from string
          const regex = new RegExp(this.calculated.resourceKeyword, 'i')

          // search for keyword in string
          if (searchString.search(regex) >= 0) {
            // increment total resources found
            resourcesFound++

            //  limit total returned resources
            if (resourcesFound <= this.calculated.resourceLimit) return true
            else return false
          } else return false
        }
      )

      this.calculated.resourcesFound = resourcesFound
      return filteredResources
    },
  },
  methods: {
    // used in vuetify value-comparator operations
    simpleMatch(selected, current) {
      return current == selected
    },

    // Camel Case or sentence to Kebab Case
    // https://gist.github.com/nblackburn/875e6ff75bc8ce171c758bf75f304707#gistcomment-2588452
    toKebab(string) {
      if (!string) return ''
      if (string.trim().search(' ') > -1) {
        return string.replace(/\s+/g, '-').toLowerCase()
      } else {
        return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
      }
    },
    // Kebab Case or sentence to Camel Case
    // https://stackoverflow.com/questions/57556471/convert-kebab-case-to-camelcase-javascript
    toCamel(string) {
      if (!string) return ''
      if (string.trim().search(' ') > -1) {
        return string.replace(/\s+/g, (x) => x.toUpperCase()[1])
      } else {
        return string.replace(/-./g, (x) => x.toUpperCase()[1])
      }
    },
    // Convert kebeb or camel case to titlecase string
    toTitle(string) {
      if (!string) return ''
      if (string.trim().search('-') > -1) {
        return string
          .replace(/-/g, ' ')
          .toLowerCase()
          .split(' ')
          .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1)
          })
          .join(' ')
      } else {
        return string
          .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
          .toLowerCase()
          .split(' ')
          .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1)
          })
          .join(' ')
      }
    },
    getAttribIcon(slug) {
      if (
        slug == 'black-african-american-advisors' ||
        slug == 'asian-pacific-islander-advisors' ||
        slug == 'latino-a-advisors' ||
        slug == 'native-american-advisors'
      ) {
        slug = 'race'
      }
      return `/img/icons/icon-${slug}.svg`
    },
    csvToArray(csv) {
      let arr = csv.split(',').map(function (item) {
        return item.trim()
      })

      return arr
    },
    resetResourceLimit() {
      this.calculated.resourceLimit = this.RESOURCE_COUNT_PER_PAGE
    },
    isPremiumAdvisor(advisor) {
      return (
        advisor.membership_type == 'Signature' ||
        advisor.membership_type == 'Elite' ||
        this.data.settings.demoMode
      )
    },
    // map investor matching attributes to assocaited page url based on attribute type
    typeToUrl(type) {
      const types = {
        lifeStages: '/',
        householdIncome: '/investor-finances',
        investableWealth: '/investor-finances',
        preferredAttributes: '/investor-preferences',
        lifeEvents: '/investor-situation',
        importantFactors: '/investor-priorities',
      }
      return types[type]
    },
    // Show preferred name (Alias vs. First)
    preferredName(advisor) {
      if (advisor.alias && advisor.alias.length > 0) {
        return advisor.alias
      }
      else {
        return advisor.first_name
      }
    },
    // fix url with or without http
    fixUrl(url) {
      return url.indexOf('http') == -1 ? 'http://' + url : url
    },
    onScroll() {
      this.scrollInvoked++
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },
}
