import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import AllData from '@/graphql/AllData.gql'
import { getField, updateField } from 'vuex-map-fields'
import { v4 as uuidv4 } from 'uuid'

Vue.use(Vuex)

// use for getting query strings - https://stackoverflow.com/a/901144
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
})

export default new Vuex.Store({
  state: {
    advisorProfile: {
      intro: '',
      icons: [],
      photos: [],
      videos: [],
    },
    user: {
      anon_token: '',
      imca_token: '',
      cst_key: '',
      cst_no: '',
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      city: '',
      state: '',
      postal_code: '',
      agreed_to_terms: false,
      iwi_can_contact: false,
      profile: {},
      favorite_advisors: [],
      userType: '',
      advisors: {},
    },
    calculated: {
      matchFilter: [],
      resourceKeyword: '',
      resourceLimit: 4,
      resourcesFound: 0,
    },
    data: {},
    appContent: {},
    appState: {
      loggedIn: false,
      checkingAuth: false,
      minWrap: false,
      setUpAccount: false,
    },
    loading: true,
  },
  mutations: {
    ADD_ICON(state) {
      state.advisorProfile.icons.push({
        id: uuidv4(),
        title: '',
        file_uuid: '',
        src: '',
        link: '',
        deleted: false,
      })
    },
    ADD_PHOTO(state) {
      state.advisorProfile.photos.push({
        id: uuidv4(),
        caption: '',
        file_uuid: '',
        src: '',
        deleted: false,
      })
    },
    ADD_VIDEO(state) {
      state.advisorProfile.videos.push({
        id: uuidv4(),
        caption: '',
        link: '',
        deleted: false,
      })
    },
    SET_DATA(state, data) {
      state.data = data
    },
    SET_APP_CONTENT(state, appContent) {
      state.appContent = appContent
    },
    CHANGE_LOADING_STATUS(state, loading) {
      state.loading = loading
    },
    updateField,
  },
  actions: {
    addIcon({ commit }) {
      commit('ADD_ICON')
    },

    addPhoto({ commit }) {
      commit('ADD_PHOTO')
    },

    addVideo({ commit }) {
      commit('ADD_VIDEO')
    },

    // Main data and appContent object from Craft GraphQL
    loadCmsData({ state, commit, getters }) {
      return new Promise((resolve, reject) => {
        const dataQuery = {
          query: AllData.loc.source.body,
        }

        // Pass the craft token so that drafts display properly in craft cms preview
        const headers = {
          headers: {
            'X-Craft-Token': params.token,
          },
        }

        const dataRequest = axios.post('/api', dataQuery, headers)

        dataRequest
          .then((response) => {
            let data = response.data.data

            // Set data in store
            commit('SET_DATA', data)

            resolve({ success: true })
          })

          .catch((error) => {
            console.log('err', error)
            reject(error)
          })
      })
    },

    // Process and parse CMS data and content
    processCmsData({ state, commit, getters }) {
      return new Promise((resolve, reject) => {
        // build investor profile lookup
        state.data.profile = {
          // importantFactors
          ...state.data.importantFactors.reduce(
            (acc, obj) => ((acc[obj.id] = obj), acc),
            {}
          ),
          // lifeEvents
          ...state.data.lifeEvents.reduce(
            (acc, obj) => ((acc[obj.id] = obj), acc),
            {}
          ),
          // preferredAttributes
          ...state.data.preferredAttributes.reduce(
            (acc, obj) => ((acc[obj.id] = obj), acc),
            {}
          ),
          // lifeStages
          ...state.data.lifeStages.reduce(
            (acc, obj) => ((acc[obj.id] = obj), acc),
            {}
          ),
          // whenToStart
          ...state.data.whenToStart.reduce(
            (acc, obj) => ((acc[obj.id] = obj), acc),
            {}
          ),
          // investableWealth
          ...state.data.investableWealth.reduce(
            (acc, obj) => ((acc[obj.id] = obj), acc),
            {}
          ),
          // householdIncome
          ...state.data.householdIncome.reduce(
            (acc, obj) => ((acc[obj.id] = obj), acc),
            {}
          ),
        }

        // Convert certifications array to key/value lookup
        state.data.certifications = state.data.certifications.reduce(
          (acc, obj) => ((acc[obj.slug] = obj), acc),
          {}
        )

        // Set filtered resources to current array and convert resources array to key/value lookup
        state.data.resources = state.data.resources.reduce(
          (acc, obj) => ((acc[obj.slug] = obj), acc),
          {}
        )

        // Build investor attribute key value id lookup across: importantFactors, lifeEvents, preferredAttributes, lifeStage, whenToStart, investableWealth and householdIncome
        state.data.attribLookup = {
          ...state.data.importantFactors.reduce(
            (acc, obj) => ((acc[obj.id] = 'importantFactors'), acc),
            {}
          ),
          ...state.data.lifeEvents.reduce(
            (acc, obj) => ((acc[obj.id] = 'lifeEvents'), acc),
            {}
          ),
          ...state.data.preferredAttributes.reduce(
            (acc, obj) => ((acc[obj.id] = 'preferredAttributes'), acc),
            {}
          ),
          ...state.data.lifeStages.reduce(
            (acc, obj) => ((acc[obj.id] = 'lifeStage'), acc),
            {}
          ),
          ...state.data.whenToStart.reduce(
            (acc, obj) => ((acc[obj.id] = 'whenToStart'), acc),
            {}
          ),
          ...state.data.investableWealth.reduce(
            (acc, obj) => ((acc[obj.id] = 'investableWealth'), acc),
            {}
          ),
          ...state.data.householdIncome.reduce(
            (acc, obj) => ((acc[obj.id] = 'householdIncome'), acc),
            {}
          ),
          ...{ '00000000000': 'nearby' },
        }

        // convert lifeStageGapMultiplier to simple val array
        state.data.settings.lifeStageGapMultiplier =
          state.data.settings.lifeStageGapMultiplier.map((obj) => {
            return obj.score
          })

        // loop through all resources to convert to uppercase lookups
        const keysToSet = [
          'assetsUnderAdvisement',
          'designations',
          'experience',
          'firmAffiliation',
          'professionalResponsibilities',
          'typesOfClients',
        ]
        keysToSet.forEach((key) => {
          state.data[key] = state.data[key].reduce(
            (acc, obj) => ((acc[obj.slug.toUpperCase()] = obj), acc),
            {}
          )
        })

        // build NetFORUM lookups
        // lifeStages
        state.data['advLifeStages'] = state.data.lifeStages.map(
          (obj) => obj.netforumValues
        )
        state.data['invLifeStages'] = state.data.lifeStages.map((obj) => obj.id)

        // investableWealth
        state.data['advInvestableWealth'] = state.data.investableWealth.map(
          (obj) => obj.netforumValues
        )
        state.data['invInvestableWealth'] = state.data.investableWealth.map(
          (obj) => obj.id
        )

        // advisorCoreCompetencies remove all based on ID of 99
        state.data['advCertById'] = state.data.advisorCoreCompetencies.reduce(
          (acc, obj) => ((acc[obj.id] = obj.netforumValues), acc),
          {}
        )
        delete state.data.advCertById['99']

        // add max and threshold cert values to importantFactors
        for (const [index, factor] of state.data.importantFactors.entries()) {
          state.data.importantFactors[index]['maxCertScore'] = Math.max(
            factor.cfpScore,
            factor.cimaScore,
            factor.cpwaScore,
            factor.rmaScore
          )
          state.data.importantFactors[index]['thresholdScore'] =
            (factor.cfpScore +
              factor.cimaScore +
              factor.cpwaScore +
              factor.rmaScore) /
            4
        }

        // add max and threshold cert values to lifeEvents
        for (const [index, factor] of state.data.lifeEvents.entries()) {
          state.data.lifeEvents[index]['maxCertScore'] = Math.max(
            factor.cfpScore,
            factor.cimaScore,
            factor.cpwaScore,
            factor.rmaScore
          )

          state.data.lifeEvents[index]['thresholdScore'] =
            (factor.cfpScore +
              factor.cimaScore +
              factor.cpwaScore +
              factor.rmaScore) /
            4
        }

        resolve({ success: true })
      })
    },

    // Contact Advisor
    contactAdvisor({ state }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/napi/contact-advisor',
          data: 'data=' + JSON.stringify(data),
        })
          .then((response) => {
            if (response.data.success) {
              resolve(response)
            } else {
              resolve(response)
            }
          })

          .catch((error) => {
            console.log('err', error)
          })
      })
    },

    // login investor
    createInvestorAccount({ state }, credentials) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/napi/createAccount',
          data: 'credentials=' + JSON.stringify(credentials),
        })
          .then((response) => {
            if (response.data.success) {
              // if successful, copy anon user profile to new investor profile
              state.user.new_user_profile = state.user.profile

              resolve(response.data)
            } else {
              resolve({ success: false, response: response })
            }
          })

          .catch((error) => {
            console.log('err', error)
          })
      })
    },

    // login investor
    investorLogin({ state }, credentials) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/napi/login',
          data: 'credentials=' + JSON.stringify(credentials),
        })
          .then((response) => {
            if (response.data.success) {
              const investor = response.data.data
              const investorAdvisors = {}

              // set advisor lookups for investor
              for (const advisor of investor.advisors) {
                investorAdvisors[advisor.cst_key] = advisor
              }

              // remove advisor array and update to advisor lookup
              delete investor.advisors
              investor.advisors = investorAdvisors

              state.user = { ...this.state.user, ...investor }
              localStorage.setItem('imca_token', this.state.user.imca_token)

              // if new user profile, write over empty profile with anon profile and remove old anon token
              if (state.user.new_user_profile) {
                state.user.profile = state.user.new_user_profile
                localStorage.removeItem('anon_token')
              }
              state.appState.loggedIn = true
              resolve(response)
            } else if (localStorage.getItem('imca_token')) {
              this.dispatch('investorLogout')
              resolve({ success: false, response: 'Invalid IMCA_TOKEN' })
            } else {
              resolve({ success: false, response: response })
            }
          })

          .catch((error) => {
            console.log('err', error)
          })
      })
    },

    // login anonymous
    anonLogin({ state }) {
      const credentials = {
        anon_token: localStorage.getItem('anon_token') || uuidv4(),
      }

      // console.log('ANON_TOKEN:', credentials.anon_token)

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/napi/login',
          data: 'credentials=' + JSON.stringify(credentials),
        })
          .then((response) => {
            if (response.data.success) {
              // console.log(response.data)
              const investor = response.data.data
              const investorAdvisors = {}

              // set advisor lookups for investor
              for (const advisor of investor.advisors) {
                investorAdvisors[advisor.cst_key] = advisor
              }

              // remove advisor array and updaate to advisor lookup
              delete investor.advisors
              investor.advisors = investorAdvisors

              state.user = { ...this.state.user, ...investor }
              localStorage.setItem('anon_token', this.state.user.anon_token)
              resolve(response)
            } else {
              resolve({ success: false, response: response })
            }
          })

          .catch((error) => {
            console.log('err', error)
          })
      })
    },

    // logout investor by clearing state and reloading root path
    investorLogout({ state }) {
      localStorage.removeItem('imca_token')
      window.location.assign('/')
    },

    // logout investor and anonymous user by clearing state and reloading root path
    clearAllData({ state }) {
      localStorage.clear()
      location.assign('/')
    },

    // update investor profile
    updateInvestorProfile({ state }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/napi/update-investor-profile',
          data: 'investor=' + JSON.stringify(state.user),
        })
          .then((response) => {
            if (response.data.success) {
              resolve({ success: true })
            } else {
              resolve({ success: false, response: response })
            }
          })

          .catch((error) => {
            console.log('err', error)
          })
      })
    },

    // Main data object from Craft GraphQL
    initApp({ commit, state }) {
      const imca_token = localStorage.getItem('imca_token')
      const loginType = imca_token ? 'investorLogin' : 'anonLogin'

      // console.log('LOGIN_TYPE:', loginType)

      // start data/content load and processing
      this.dispatch('loadCmsData')
        .then((response) => {
          if (response.success) {
            return this.dispatch('processCmsData')
          } else {
            this.serverError = true
          }
        })

        .then((response) => {
          if (response.success) {
            return this.dispatch(loginType, { imca_token: imca_token })
          } else {
            this.serverError = true
          }
        })

        .then((response) => {
          // console.log('LOGIN_TYPE_RESPONSE:', response)

          // this.isLoggingIn = false

          if (response.data && response.status == 200) {
            // Once data load complete, set dataLoading to false
            state.loading = false
            // do something
          } else {
            this.serverError = true
          }
        })
        .catch((error) => {
          console.log('err', error)
        })
    },
  },
  getters: {
    data(state) {
      return state.data
    },

    appContent(state) {
      return state.appContent
    },

    dataLoading(state) {
      return state.loading
    },
    getField,
  },
})
