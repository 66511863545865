<template>
  <v-container class="below-header advisor-detail">
    <advisor-card :panel="[0]" :advisors="advisors"></advisor-card>
  </v-container>
</template>

<script>
  import { validate as uuidValidate } from 'uuid'

  import hasura from '../services/hasura'
  import axios from 'axios'

  // COMPONENTS
  import AdvisorCard from '@/components/AdvisorCard'

  export default {
    name: 'AdvisorDetail',
    components: { AdvisorCard },
    data() {
      return {
        advisors: [],
        cst_no: parseInt(this.$route.params.cstNo),
      }
    },
    computed: {},
    methods: {
      getAdvisor() {
        hasura
          .getAdvisors({ cst_no: this.cst_no })
          .then((results) => {
            const data = results.data

            this.advisors = [{ ...data.advisors[0], ...{ detail_only: true } }]
          })
          .catch((error) => {
            console.log('err', error)
            // reject(error)
          })
      },
    },
    updated() {},
    mounted() {
      this.appState.minWrap = true
      // onload get advsisor if valid customer number passed
      if (!isNaN(this.cst_no)) this.getAdvisor()
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
  .below-header.advisor-detail {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: @text-color;
    }

    .big-screen {
      display: none;
    }

    @media screen and (min-width: 600px) {
      .big-screen {
        display: block;

        h6 {
          text-transform: uppercase;
        }
      }

      .small-screen {
        display: none;
      }
    }
  }
</style>
