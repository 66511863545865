<template>
  <v-container class="not-found">
    <h1>404 - Not Found</h1>
  </v-container>
</template>

<script>
  export default {
    name: 'Notfound',
    components: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .not-found {
    text-align: center;
  }
</style>
