<template>
  <div
    class="certifications"
    :class="
      panel ||
      this.$route.name == 'Resources' ||
      this.$route.name == 'Certifications'
        ? 'panel'
        : ''
    "
  >
    <v-container>
      <h4>We make good advisors exceptional advisors</h4>
      <h2>
        The Investments &amp; Wealth Institute is a professional association,
        advanced education provider and standards body for financial advisors,
        investment consultants and wealth managers who embrace excellence and
        ethics.
      </h2>
      <p>
        Our industry-leading certifications give advisors the tools and
        knowledge to help you achieve your financial dreams.
      </p>
      <div class="callouts">
        <template v-for="cert in Object.values(data.certifications)">
          <div class="callout" :key="cert.id">
            <svg-img
              width="120px"
              :svgId="cert.svgIcon[0].id"
              :svgUrl="cert.svgIcon[0].url"
              :svgTitle="cert.svgIcon[0].title"
            ></svg-img>
            <router-link :to="'/' + cert.uri">
              <h3>{{ cert.title }} ({{ cert.acronym }}) Certification</h3>
            </router-link>
            <p>{{ cert.description }}</p>
            <!-- TODO: Accessibility, Remove either router-link or btn -->
            <router-link :to="'/' + cert.uri">
              <v-btn>Learn about {{ cert.acronym }} Certification</v-btn>
            </router-link>
          </div>
          <v-divider
            class="mx-2 dashed"
            vertical
            :key="'divider' + cert.id"
          ></v-divider>
        </template>
      </div>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'Certifications',
    components: {},
    props: {
      panel: Boolean,
    },
    data() {
      return {}
    },
    computed: {},
    methods: {
      nav() {
        //do something
      },
    },
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
  .certifications {
    padding: 2em 0;
    background: white;
    a {
      text-decoration: none;
    }
    h2,
    h4,
    p {
      text-align: center;
    }

    h2 {
      margin: 0.5em 0;
      font-size: @font-size-h4;
    }

    h4 {
      font-size: @font-size-base;
    }
    &.panel {
      margin: unset;
    }

    .callouts {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      margin: 2em 0;

      & > hr:last-child {
        display: none;
      }

      .callout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 1em;
        flex-basis: 33.3%;
        h3,
        p {
          text-align: center;
        }

        h3 {
          margin: 1em 0;
          font-size: @font-size-uphalf;
          text-decoration: underline;
        }

        img {
          height: 7em;
        }

        .v-btn {
          margin-top: 0.5em;
          margin-bottom: 1em;
          text-transform: capitalize;
          max-width: 358px;
        }
      }
    }

    @media screen and (min-width: 960px) {
      h2 {
        margin: 1em 0;
        font-size: @font-size-h2;
      }

      h4 {
        font-size: @font-size-h4;
      }
      .callouts {
        flex-direction: row;
        h3 {
          text-decoration: none !important;
        }

        .callout {
          max-width: 404px;

          .v-btn {
            margin-top: 2em;
            margin-bottom: 0;
          }
        }
      }
    }
    @media screen and (max-width: 599px) {
      .certifications {
        h2 {
          margin: 1em auto !important;
        }
      }
    }
  }
</style>
