<template>
  <div class="certification">
    <h2 class="container">Resources and Education</h2>
    <article :key="certification.id">
      <v-container>
        <v-card>
          <v-container>
            <v-row class="svg-row">
              <v-col
                class="svg-col"
                cols="2"
                offset-md="1"
                v-if="certification.svgIcon[0]"
              >
                <svg-img
                  class="cert-svg"
                  :svgId="certification.svgIcon[0].id"
                  :svgUrl="certification.svgIcon[0].url"
                  :svgTitle="certification.svgIcon[0].title"
                ></svg-img>
              </v-col>
              <v-col cols="10" md="10">
                <h2>
                  {{ certification.title }}
                  <span v-if="certification.acronym">
                    ({{ certification.acronym }})
                  </span>
                  Certification
                </h2>
                <p class="subtitle" v-if="certification.subline">
                  {{ certification.subline }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="certification.summary">
              <v-col cols="12" md="8" offset-md="1">
                <p class="summary">
                  {{ certification.summary }}
                </p>
              </v-col>
            </v-row>
            <v-row class="row-content-matrix">
              <v-col
                md="certification.centerColComp.length ? 3 : 8"
                offset-md="1"
                class="left-col"
                v-if="certification.leftColComp.length"
              >
                <aside class="content-matrix">
                  <content-matrix
                    :contentArray="certification.leftColComp"
                  ></content-matrix>
                </aside>
              </v-col>
              <v-col
                md="certification.leftColComp.length ? 4 : 8"
                class="center-col"
                v-if="certification.centerColComp.length"
              >
                <content-matrix
                  :contentArray="certification.centerColComp"
                ></content-matrix>
              </v-col>
              <v-col md="4" class="right-col">
                <aside class="resources">
                  <h3>Resources &amp; Education</h3>
                  <certs-list></certs-list>
                  <resource-other></resource-other>
                </aside>
                <statistic
                  :statId="
                    certification.displayStatistic[0]
                      ? certification.displayStatistic[0].id
                      : null
                  "
                ></statistic>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>

      <v-container v-if="!$vuetify.breakpoint.xsOnly" class="supplement panel">
        <v-row>
          <v-col>
            <h3>Institute code of professional responsibility</h3>
            <p>
              <em>The Code</em>
              was adopted to promote and maintain a high standard of
              professional conduct in the investment consulting and wealth
              management disciplines. Investments &amp; Wealth Institute
              members, candidates in the Institute certification programs and
              Institute certificants are required to adhere to the following
              principles:
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="5">
            <ul>
              <li>
                <v-icon>mdi-check</v-icon>
                Act in the best interest of the client.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Disclose services to be offered and provided, related charges,
                and compensation.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Disclose the existence of actual, potential, and/or perceived
                conflicts of interest and relevant financial relationships,
                direct and/or indirect. Take appropriate action to resolve or
                manage any such conflicts.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Provide clients information needed to make informed decisions.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Respond to client inquiries and instructions appropriately,
                promptly, completely, and truthfully.
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="5">
            <ul>
              <li>
                <v-icon>mdi-check</v-icon>
                Maintain confidentiality of client information, however
                acquired, consistent with legal and regulatory requirements and
                firm policies.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Provide competent service by truthful representation of
                competency, maintenance and/or development of professional
                capabilities, and, when appropriate, the recommendation of other
                professionals.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Comply with legal and regulatory requirements related to one's
                practice of his or her profession.
              </li>
              <li>
                <v-icon>mdi-check</v-icon>
                Maintain a high level of ethical conduct.
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </article>
  </div>
</template>

<script>
  import ContentMatrix from '@/components/ContentMatrix'
  import Statistic from '@/components/Statistic'
  import CertsList from '@/components/CertsList'
  import ResourceOther from '@/components/ResourceOther'

  export default {
    components: {
      ContentMatrix,
      Statistic,
      CertsList,
      ResourceOther,
    },
    data() {
      return {}
    },

    computed: {
      certification() {
        // console.log('this.data.resources:', this.data.resources)
        return this.data.certifications[this.$route.params.certificationSlug]
      },
    },
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .certification {
    margin-bottom: 5em;
    h2.container {
      font-size: 24px !important;
      margin: 3em 0 0.5em 0 !important;
      width: 60%;
      font-weight: 800 !important;
    }

    a {
      color: @text-color !important;
    }

    & + .disclaimer {
      display: none;
    }

    article {
      h2 {
        color: @brand-yellow;
        font-size: @font-size-uphalf !important;
        margin: 0 0 0.25em;
      }
      .svg-row {
        .col-10 {
          max-width: none;
        }
      }
      .cert-svg {
        width: 160px;
        height: 170px;
      }
      .row-content-matrix {
        flex-direction: column;
      }

      .v-card {
        padding-bottom: 5em;

        & > .container > div:first-child {
          flex-direction: column;
        }
      }

      .left-col {
        ol {
          counter-reset: li-count;

          li {
            counter-increment: li-count;
            list-style-type: none;
            position: relative;
            margin-left: 3em;
            min-height: 3.5em;
            padding-bottom: 0.25em;
            line-height: 1.25em;

            &:before {
              content: counter(li-count);
              position: absolute;
              top: 0;
              left: -3em;
              font-size: @font-size-upbit;
              font-weight: 700;
              font-family: @font-family-serif;
              color: @brand-dark-gray;
              background: @brand-yellow;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 2em;
              width: 2em;
              line-height: 1em;
              border-radius: 50%;
            }

            h4 {
              font-size: @font-size-base;
              color: @brand-dark-gray;
              margin-bottom: 0;
              line-height: 1.25em;
            }

            p {
              font-size: @font-size-mini;
            }
          }
        }
      }

      .center-col {
        h3 {
          color: @text-color;
          font-family: @font-family-base;
          font-size: @font-size-base;
        }

        h4 {
          color: @text-color;
        }

        hr {
          border-bottom: dotted @brand-dark-blue 1px;
          border-width: 0 0 1px 0;
          margin: 2em 0;
        }
      }

      .right-col {
        h4 {
          text-transform: uppercase;
          color: @brand-dark-gray;
          font-weight: 700;
          margin-bottom: 1em;
        }

        ul,
        li {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }

        li {
          margin-bottom: 0.25em;
        }

        ul {
          margin-bottom: 2em;
          padding-bottom: 2em;

          &:not(:last-child) {
            border-bottom: dotted @brand-dark-blue 1px;
          }
        }
      }

      .subtitle {
        font-family: @font-family-serif;
        font-size: @font-size-uphalf;
        line-height: 1.25;
        margin-top: 1em;
      }

      .summary {
        font-size: @font-size-uphalf;
        font-weight: 700;
        line-height: 1.083em;
      }

      h3 {
        text-transform: uppercase;
        font-size: @font-size-upbit;
        font-weight: 700;
        margin: 1em 0;
      }

      h4 {
        text-transform: none;
        font-size: @font-size-base;
        margin: 1em 0 0 0;
        line-height: 1.5em;
      }

      aside {
        h3 {
          color: @brand-deep-yellow;
        }

        &.resources {
          background-color: rgba(112, 148, 170, 0.11);
          padding: 1em 1.5em 3em;
          position: relative;
          right: -12px;
        }
        &.content-matrix {
          ol {
            padding-left: 0.4rem;
          }
        }
      }
    }

    .supplement {
      background-color: @brand-blue-gray;
      color: @brand-white;

      h3 {
        font-weight: 700;
        color: @brand-yellow;
        font-size: @font-size-h3;
        text-transform: none;
        text-align: center;
        margin: 1em 0 0.5em 0;

        & + p {
          color: @brand-white;
          font-family: @font-family-serif;
          text-align: center;
          max-width: 80%;
          margin: auto;
        }
      }

      p {
        font-family: @font-family-serif;
      }

      li {
        position: relative;
        list-style-type: none;
        margin-left: 1.5em;
        margin-bottom: 1em;
        line-height: 1.5em;

        .v-icon {
          position: absolute;
          background: @brand-yellow;
          border-radius: 50%;
          left: -1.5em;
        }
      }
    }
  }
  @media screen and (min-width: 700px) {
    .certification {
      h2.container {
        margin: 4.5em auto 0.5em !important;
        width: 100%;
      }
      article {
        h2 {
          font-size: @font-size-double !important;
        }
        .subtitle {
          line-height: 1.6;
          margin-top: 0;
        }
        .svg-col {
          margin-right: 0em;
        }
        .svg-row {
          margin-top: 0;
          // flex-wrap: nowrap;
          .col-10 {
            max-width: 70.3%;
          }
        }
        .row-content-matrix {
          flex-direction: row;
        }
        .v-card {
          & > .container > div:first-child {
            flex-direction: row;
          }
        }
        h4 {
          font-size: @font-size-base;
        }
      }
    }
  }
</style>
