<template>
  <div class="page-container">User Icon</div>
</template>

<script>
  export default {
    name: 'UserIcon',
    components: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
@import (reference) '~@/less/variables.less';
</style>
