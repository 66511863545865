<template>
  <div class="resource-landing">
    <h2>Resources &amp; Investment Help</h2>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {}
    },

    computed: {},

    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .resource-landing {
    & + .disclaimer {
      display: none;
    }
    h2 {
      color: @brand-white;
      margin: 6em auto 1em;
      width: 90%;
      max-width: 40em;
    }
  }
</style>
