import { render, staticRenderFns } from "./MatchNavigation.vue?vue&type=template&id=477c2142&"
import script from "./MatchNavigation.vue?vue&type=script&lang=js&"
export * from "./MatchNavigation.vue?vue&type=script&lang=js&"
import style0 from "./MatchNavigation.vue?vue&type=style&index=0&id=477c2142&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports