<template>
  <nav class="match-navigation">
    <router-link :to="steps[this.navProgress]" class="next-button">
      <span>Next</span>
      {{ stepLabels[this.navProgress] }}
    </router-link>
    <!-- <hr /> -->
    <div class="dotted-spaced"></div>
    <ul>
      <li
        v-for="(step, index) in steps"
        :class="calcClasses(index + 1)"
        :key="index"
      >
        <router-link :to="steps[index]">
          <p>NEXT</p>
          <progress-status></progress-status>
          <span>{{ stepLabels[index] }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
  import ProgressStatus from '@/components/ProgressStatus'

  export default {
    name: 'MatchNavigation',
    components: { ProgressStatus },
    props: { navProgress: Number },
    data() {
      return {
        steps: ['/','/','/','/','/','/'],
        stepLabels: ['Life Stage','Investor Preferences','Investor Preferences','Investor Preferences','Investor Preferences','Investor Preferences'],
      }
    },
    computed: {},
    methods: {
      calcClasses(step) {
        var classVal = ''
        var progress = this.navProgress
        if (step == progress) {
          classVal += 'current'
        } else if (step == progress + 1) {
          classVal += 'next'
        } else if (step < progress) {
          classVal += 'complete'
        }

        return classVal
      },
      setPageOrder() {
        this.steps = []
        for(let page of this.data.settings.matchingPageOrder) {
          //console.log(page.stepSlug)
          this.steps.push(page.stepSlug)
        }
      },
      setPageLabels() {
        this.stepLabels = []
        for(let page of this.data.settings.matchingPageOrder) {
          //console.log(page.stepLabel)
          this.stepLabels.push(page.stepLabel)
        }
      },
    },
    updated() {},
    mounted() {
      this.setPageOrder()
      this.setPageLabels()
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
  .match-navigation {
    .next-button {
      background: white;
      border: solid @brand-yellow 2px;
      color: @brand-dark-gray;
      display: block;
      width: 12em;
      margin: auto;
      text-align: center;
      border-radius: 5px;
      padding: 0.5em 2em;
      text-decoration: none;
      box-shadow: rgba(0, 0, 0, 0.5) 0 3px 19px 7px;

      span {
        display: block;
        padding: 0;
        margin: 0;
        font-size: @font-size-upthird;
        text-transform: uppercase;
        line-height: 1.1em;
        font-family: @font-family-serif;
        font-weight: 700;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 1em 0 0 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      li {
        list-style-type: none;

        & > a {
          padding: 1em 0;
          margin: 0;
          color: white;
          display: flex;
          flex-direction: column;
          align-content: center;
          align-items: center;
          border-radius: 5px;
          text-align: center;
          text-decoration: none;

          & > p {
            display: none;
            padding: 0;
            margin: 0;
            text-transform: uppercase;
            opacity: 0.5;
            font-size: @font-size-upthird;
            font-family: @font-family-serif;
            font-weight: 700;
            line-height: 1.1em;
          }

          span {
            color: white;
            padding-top: 0.75em;
            font-size: @font-size-micro;
            text-decoration: none;
            opacity: 0.5;
          }
        }

        &.current {
          & > a {
            text-decoration: none;
            & > span {
              opacity: 1;
            }
          }
          .progress-status {
            background: @brand-yellow;
            opacity: 1;
          }
        }

        &.complete {
          & > a {
            & > span {
              opacity: 1;
              text-decoration: underline;

              .progress-status {
                background: @brand-yellow;
                .v-icon {
                  opacity: 1;
                }
              }
            }
          }
        }

        &.next {
          p {
            display: none;
          }

          & > span {
            text-decoration: none;
            color: @brand-dark-gray;
          }
        }
      }
    }

    .dotted-spaced {
      position: relative;
      top: 5.8rem;
      height: 1px;
      width: 89%;
      margin: auto;
      background-image: linear-gradient(
        to right,
        @brand-light-blue 10%,
        rgba(255, 255, 255, 0) 5%
      );
      background-position: 0% 24%; //left top
      background-size: 12px 1px;
      background-repeat: repeat-x;
    }
  }

  @media screen and (min-width: 600px) {
    .match-navigation {
      .next-button {
        display: none;
      }

      ul {
        margin-top: 4em;
        position: relative;

        li {
          & > a {
            padding: 1em 2em;
            span {
              font-size: @font-size-mini;
            }
          }

          &.current {
            & > a {
              background: white;
              color: @brand-dark-gray;
              box-shadow: rgba(0, 0, 0, 0.5) 0 3px 19px 7px;
              text-decoration: none;
              position: relative;

              &:before {
                content: '';
                height: 1em;
                width: 1em;
                background-color: white;
                position: absolute;
                top: -0.5em;
                left: 50%;
                margin-left: -0.5em;
                transform: rotate(45deg);
                box-shadow: rgba(0, 0, 0, 0.5) 0 0 8px -5px;
              }

              .progress-status {
                display: none;
              }

              span {
                padding: 0.4em 0;
                color: @brand-dark-gray;
                opacity: 1;
                font-family: @font-family-serif;
                font-weight: 700;
                font-size: 1.25em;
              }
            }
          }

          /*&.next { OLD NEXT STYLE
            & > a {
              background: white;
              border: solid @brand-yellow 2px;
              color: @brand-dark-gray;
              box-shadow: rgba(0, 0, 0, 0.5) 0 3px 19px 7px;
              text-decoration: none;

              .progress-status {
                display: none;
              }

              p {
                color: @brand-dark-gray;
                display: block;
              }

              span {
                padding-top: 0.4em;
                color: @brand-dark-gray;
              }
            }
          }*/

          &.next {
            & > a {
              p {
                text-decoration: none;
                color: @text-color;
                border: solid @brand-yellow 1px;
                padding: 0.5em 1.25em;
                font-weight: 700;
                font-size: 1em;
                opacity: 1;
                display: block;
                margin-top: -7%;
                font-family: @font-family-base;
                background: @brand-yellow;
              }

              span {
                color: white;
                text-decoration: underline;
                opacity: 1;
              }

              .progress-status {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 599px) {
    .match-navigation {
      margin-top: 0.8rem;
      .complete {
        .progress-status {
          background: @brand-yellow;
        }
      }
      // & > a {
      //   & > span {
      //     opacity: 1;
      //     text-decoration: underline;
      //     // color: @brand-yellow;

      //     .progress-status {
      //       background: @brand-yellow;
      //       // .v-icon {
      //       //   opacity: 1;
      //       // }
      //     }
      //   }
      // }
    }
    .dotted-spaced {
      top: 2.5rem !important;
      width: 88% !important;
    }
  }
</style>
