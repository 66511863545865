<template>
  <div id="about-me">
    <h4>About Me</h4>

    <v-chip-group column>
      <v-tooltip bottom v-for="(attrib, index) in aboutMe" :key="index">
        <template v-slot:activator="{ on, attrs }">
          <router-link :to="typeToUrl(attrib.type)">
            <v-avatar size="36" color="white" v-bind="attrs" v-on="on">
              <img
                :src="`/img/icons/icon-${attrib.slug}.svg`"
                class="about-me-icon"
              />
            </v-avatar>
          </router-link>
        </template>
        <span>{{ attrib.title }}</span>
      </v-tooltip>
    </v-chip-group>
  </div>
</template>

<script>
  export default {
    name: 'AboutMe',
    components: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  #about-me {
    position: relative;
    max-width: 16em;
    width: 30%;
    padding-left: 0.75em;
    border-left: solid @brand-yellow 0.5px;
    margin: 0.5em 3em 0 0;

    h4 {
      text-transform: none;
      font-size: @font-size-base;
      line-height: 1.1em;
      margin-bottom: 0.25em;
    }

    .v-avatar {
      margin-left: 0.25em;
      margin-bottom: 0.25em;
    }

    .about-me-icon {
      padding: 0.3125em;
      height: 1.9em;
      width: 1.9em;
      border-radius: 0;
    }
  }
  @media screen and (max-width: 599px) {
    #about-me {
      display: none;
    }
  }
</style>
