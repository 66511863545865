<template>
  <div class="statistic" :key="stat.id">
    <span class="value">{{ stat.statistic }}</span>
    <p class="statement">
      {{ stat.summary }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'Statistic',
    components: {},
    props: {
      statId: null,
    },
    data() {
      return {
        stat: {},
      }
    },
    computed: {},
    methods: {
      setStat() {
        // only include stats that are set to be included as random
        let statsToDisplay = []

        // if stat id passed in just display that one, otherwise pick a random one
        if (this.statId) {
          this.data.statistics.forEach((stat) => {
            if (stat.id == this.statId) this.stat = stat
          })
        } else {
          this.data.statistics.forEach((stat) => {
            if (stat.includeInRandomStats) statsToDisplay.push(stat)
          })

          if (statsToDisplay.length) {
            const index = Math.floor(Math.random() * statsToDisplay.length)
            this.stat = statsToDisplay[index]
          }
        }
      },
    },
    updated() {
      this.setStat()
    },
    mounted() {
      this.setStat()
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .statistic {
    text-align: center;
    padding: 1em 4em;

    .value {
      color: @brand-deep-yellow;
      font-size: 6em;
      font-family: @font-family-serif;
      font-weight: 400;
      line-height: 1.2em;
    }

    .statement {
      font-family: @font-family-serif;
      font-size: @font-size-base;
      line-height: 1.44em;
      font-weight: 400;
    }
  }
</style>
