<template>
  <v-container class="below-header preferences">
    <h2>Tell us what is important to you</h2>

    <!--TODO; In app.vue we are doing display:none on class directions, do we still want that here? -->
    <v-container v-if="$vuetify.breakpoint.smAndDown" class="mobile">
      <v-card
        v-scroll.self="onScroll"
        class="overflow-y-auto"
        max-height="250"
        color="transparent"
        elevation="0"
        id="scroll-card"
      >
        <h3 class="question">When would you like to get started?</h3>
        <!-- <v-responsive> -->
        <v-btn-toggle
          v-model="user.profile.timing"
          rounded
          class="toggle-select"
          @change="$store.dispatch('updateInvestorProfile')"
          :value-comparator="simpleMatch"
        >
          <v-btn value="100">Now</v-btn>
          <v-btn value="101">1-3 mo.</v-btn>
          <v-btn value="102">3-6 mo.</v-btn>
          <v-btn value="103">6-12 mo.</v-btn>
        </v-btn-toggle>
        <!-- </v-responsive> -->
        <h3 class="question">
          Would you prefer an advisor with one or more of these attributes?
          Select all that apply.
        </h3>
        <v-btn-toggle
          multiple
          v-model="user.profile.preferredAttributes"
          @change="$store.dispatch('updateInvestorProfile')"
          :value-comparator="simpleMatch"
        >
          <div class="button-group">
            <div id="zipCodeMenu">
              <v-menu
                v-model="showZipCode"
                content-class="nearby-entry"
                transition="slide-y-transition"
                :close-on-content-click="false"
                top
                offset-y
                attach="#zipCodeMenu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="nearby-trigger">
                    <toggle-button
                      value="00000000000"
                      phrase="Nearby"
                      icon="/img/icons/icon-nearby.svg"
                      :disabled="user.postal_code == ''"
                      standalone
                    ></toggle-button>
                  </div>
                </template>

                <v-card class="nearby-location">
                  <v-container>
                    <v-row>
                      <v-col>
                        <h4><label>What is your location?</label></h4>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="user.postal_code"
                          outlined
                          dense
                          hide-details
                          placeholder="ZIP Code"
                          v-on:keyup.enter="setZipCode()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-menu>
              <toggle-button
                v-for="attribute in data.preferredAttributes"
                :key="attribute.id"
                :value="attribute.id"
                :phrase="attribute.title"
                :icon="getAttribIcon(attribute.slug)"
                :definition="attribute.definition"
                standalone
              ></toggle-button>
            </div>
          </div>
        </v-btn-toggle>
      </v-card>
      <match-navigation :navProgress="progressIndex"></match-navigation>
    </v-container>
    <!-- Screen bigger than phone -->
    <v-container v-if="$vuetify.breakpoint.mdAndUp">
      <h3 class="question">
        Would you prefer an advisor with one or more of these attributes?
      </h3>
      <p class="directions">Select all that apply.</p>

      <v-btn-toggle
        multiple
        v-model="user.profile.preferredAttributes"
        @change="$store.dispatch('updateInvestorProfile')"
        :value-comparator="simpleMatch"
      >
        <div class="button-group">
          <div id="zipCodeMenu">
            <v-menu
              v-model="showZipCode"
              content-class="nearby-entry"
              transition="slide-y-transition"
              :close-on-content-click="false"
              top
              offset-y
              attach="#zipCodeMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="nearby-trigger">
                  <toggle-button
                    value="00000000000"
                    phrase="Nearby"
                    icon="/img/icons/icon-nearby.svg"
                    :disabled="user.postal_code == ''"
                    standalone
                  ></toggle-button>
                </div>
              </template>

              <v-card class="nearby-location">
                <v-container>
                  <v-row>
                    <v-col>
                      <h4><label>What is your location?</label></h4>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="user.postal_code"
                        outlined
                        dense
                        hide-details
                        placeholder="ZIP Code"
                        v-on:keyup.enter="setZipCode()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-menu>
            <toggle-button
              v-for="attribute in data.preferredAttributes"
              :key="attribute.id"
              :value="attribute.id"
              :phrase="attribute.title"
              :icon="getAttribIcon(attribute.slug)"
              :definition="attribute.definition"
              standalone
            ></toggle-button>
          </div>
        </div>
      </v-btn-toggle>

      <h3 class="question">When would you like to get started?</h3>
      <!-- <v-responsive> -->
      <v-btn-toggle
        v-model="user.profile.timing"
        rounded
        class="toggle-select"
        @change="$store.dispatch('updateInvestorProfile')"
        :value-comparator="simpleMatch"
      >
        <v-btn value="100">Ready Now</v-btn>
        <v-btn value="101">1-3 months</v-btn>
        <v-btn value="102">3-6 months</v-btn>
        <v-btn value="103">6-12 months</v-btn>
      </v-btn-toggle>
      <match-navigation :navProgress="progressIndex"></match-navigation>
      <!-- </v-responsive> -->
    </v-container>
  </v-container>
</template>

<script>
  import MatchNavigation from '@/components/MatchNavigation'
  import ToggleButton from '@/components/ToggleButton'

  export default {
    name: 'InvestorPreferences',
    components: { MatchNavigation, ToggleButton },
    data: () => ({
      showZipCode: false,
    }),
    computed: {
      progressIndex() {
        const index = this.data.settings.matchingPageOrder.findIndex(x => x.stepSlug === 'investor-preferences')
        return index+1
      }
    },
    methods: {
      setZipCode() {
        const prefAtt = this.user.profile.preferredAttributes

        if (this.user.postal_code > 0 && !prefAtt.includes('00000000000')) {
          prefAtt.push('00000000000')
        } else {
          const index = prefAtt.indexOf('00000000000')
          prefAtt.splice(index, 1)
        }

        this.$store.dispatch('updateInvestorProfile')

        this.showZipCode = false
      },
    },
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .button-group {
    margin: 2em 0 4em;

    .v-btn {
      margin-right: 1em;
      margin-bottom: 1em;

      .v-icon--right {
        background: @brand-white;
        height: 28px;
        width: 28px;
        flex-basis: 28px;
        border-radius: 50%;
        position: relative;
        right: -4px;
        margin-left: auto;
        flex-shrink: 0;
        font-size: 24px;
      }
    }
    label {
      color: @brand-dark-blue;
    }
  }

  @media screen and (max-width: 599px) {
    .preferences {
      h2 {
        font-weight: 700 !important;
        font-size: 20px !important;
        margin-top: -2rem;
      }
      .mobile {
        h3.question {
          margin: 0rem 0rem 0.3rem 0rem;
        }
        .toggle-select {
          margin: 1em 0 2em;
        }
      }
    }
    #scroll-card {
      margin: 6% 0;
      //TODO; Do we prefer with or without?
      // box-shadow: 0px -124px 81px -65px rgba(0, 0, 0, 0.5) inset !important;
    }
    .button-group {
      text-align: center;
      margin: auto;
    }
    .v-btn:is(.toggle) {
      margin: 0.5em auto;
    }
    .v-btn__content {
      padding: 0 10%;
    }
  }

  .nearby-trigger,
  .nearby-entry {
    min-width: 300px !important;
    display: inline-block;
  }

  .nearby-entry {
    margin-top: -0.75em;
  }

  .nearby-location {
    min-width: 300px !important;
    h4 {
      font-size: @font-size-mini;
      color: @brand-dark-gray;
      text-transform: none;
      text-align: center;
    }
  }
</style>
