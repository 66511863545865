<template>
  <v-container class="below-header home">
    <h2>Are you looking for a certified and exceptional advisor?</h2>
    <p class="subtitle">
      Let's match you to an advisor with an industry-leading certification
    </p>

    <h3 class="question">What does your life look like right now?</h3>
    <p class="directions">
      Move the slider to best represent your current life stage.
    </p>
    <v-container v-if="$vuetify.breakpoint.xsOnly" class="mobile lifeStage">
      <v-btn-toggle
        v-model="user.profile.lifeStages"
        rounded
        class="toggle-select icon-toggle"
        @change="setLifestageValues()"
        :value-comparator="simpleMatch"
      >
        <v-btn
          :value="100 * index + 50"
          v-for="(lifeStage, index) in data.lifeStages"
          :key="'sm-' + lifeStage.id"
        >
          <div class="life-stage">
            <h4>{{ lifeStage.title.split(' ')[0] }}</h4>
            <p>{{ lifeStage.description }}</p>
          </div>
          <img
            :src="`/img/icons/icon-${lifeStage.slug}.svg`"
            class="drawer-icon"
          />
        </v-btn>
      </v-btn-toggle>
    </v-container>
    <v-container v-if="$vuetify.breakpoint.smAndUp">
      <div class="life-stages">
        <div
          class="life-stage"
          v-for="lifeStage in data.lifeStages"
          :key="lifeStage.id"
        >
          <h4>{{ lifeStage.title }}</h4>
          <p>{{ lifeStage.description }}</p>
        </div>
      </div>

      <div class="life-stages life-stages-slider">
        <v-slider
          v-model="user.profile.lifeStages"
          thumb-label="always"
          min="1"
          max="400"
          color="white"
          track-color="white"
          thumb-color="white"
          @mouseup="setLifestageValues()"
        >
          <template v-slot:thumb-label="{ value }">
            <img :src="getLifeStageIcon(value)" class="life-stage-icon" />
          </template>
        </v-slider>
      </div>
    </v-container>
    <match-navigation :navProgress="progressIndex"></match-navigation>
  </v-container>
</template>

<script>
  import MatchNavigation from '@/components/MatchNavigation'

  export default {
    name: 'InvestorLifeStage',
    components: { MatchNavigation },
    data() {
      return {}
    },
    computed: {
      progressIndex() {
        const index = this.data.settings.matchingPageOrder.findIndex(x => x.stepSlug === 'life-stage')
        return index+1
      }
    },
    methods: {
      getLifeStageIcon(value) {
        if (value) {
          const satisfactionEmojis = [
            'icon-lifestage-starting.svg',
            'icon-lifestage-planning.svg',
            'icon-lifestage-growingwealth.svg',
            'icon-lifestage-retirement.svg',
          ]
          let icon =
            '/img/icons/' +
            satisfactionEmojis[Math.min(Math.floor(value / 100), 4)]
          return icon
        }
      },
      setLifestageValues() {
        // set life stage ID based on slider value
        this.user.profile['lifeStageId'] =
          this.data.invLifeStages[
            Math.min(Math.floor(this.user.profile.lifeStages / 100), 4)
          ]
        // save life stage values
        this.$store.dispatch('updateInvestorProfile')
      },
    },
    updated() {},
    mounted() {
      this.setLifestageValues()
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
  // h4 {
  //   color: @brand-dark-gray !important;
  //   font-family: @font-family-base;
  //   font-size: 1.5rem !important;
  //   text-transform: none;
  // }
  .below-header {
    h3.question {
      margin: 0;
    }
  }

  .life-stages {
    display: flex;
    flex-direction: row;

    .life-stage {
      width: 26%;
      text-align: center;
      padding: 1em;

      &:first-child {
        width: 22%;
      }

      p {
        color: white;
        font-size: @font-size-mini;
        margin-bottom: 0;
      }

      h4 {
        color: @brand-yellow !important;
        font-family: @font-family-base;
        text-transform: none;
        font-size: 1.25em;
        line-height: 1.25em;
      }
    }

    &.life-stages-slider {
      .v-slider {
        height: 8em;
        z-index: 1;

        &:after {
          content: '';
          position: absolute;
          bottom: -0.8em;
          right: 0;
          height: 6em;
          width: 102%;
          background-image: url('../assets/svg/life-stage.svg');
          background-repeat: no-repeat;
          background-size: 102% auto;
          z-index: 0;
          bottom: 0.7em;
          left: -0.6em;
          right: auto;
        }

        .v-slider__track-container {
          top: auto;
          bottom: 10px;
          z-index: 1;
        }

        .v-slider__thumb-container {
          height: calc(100% - 7px);
          padding: 0;
          top: 0;
          border: solid white 1px;
          z-index: 1;

          .v-slider__thumb {
            bottom: -8px;
            top: auto;
            z-index: 1;
            cursor: pointer;
          }

          .v-slider__thumb-label {
            border-radius: 50%;
            cursor: pointer;
            font-size: 1.75em;
            height: 1.5em !important;
            width: 1.5em !important;
            left: -0.75em;
            top: -2px;
            bottom: auto;
            transform: none !important;
            position: relative;

            * {
              transform: none;
            }

            &:before,
            &:after {
              content: '';
              // background-image: url("/img/arrow-horizontal.svg");
              height: 0.5em;
              width: 0.3em;
              background-repeat: no-repeat;
              background-size: contain;
              left: -0.4em;
              top: 0.45em;
              position: absolute;
            }

            &:after {
              transform: rotate(180deg);
              right: -0.4em;
              left: auto;
            }

            div {
              display: flex;

              img {
                height: 1em;
                width: 1em;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 599px) {
    .container.mobile {
      margin: 6% 0;
      padding: 0 !important;
    }

    .home {
      h2 {
        font-size: 22px !important;
        font-weight: 900 !important;
        margin-right: 0rem !important;
      }
      p.subtitle {
        font-size: 19px !important;
        font-weight: 900 !important;
      }
      h3.question {
        font-size: 16px !important;
        font-weight: 400;
      }
    }
    .mobile.lifeStage {
      font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
      .life-stage {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap !important;
        align-items: center;

        p {
          width: 58%;
          line-height: 1.1;
        }
      }
      h4 {
        font-size: 13px !important;
        color: black !important;
        font-weight: 800 !important;
        text-transform: capitalize !important;
        margin-top: 1em;
      }
      p {
        font-size: 12px !important;
      }
      .v-btn {
        height: 6.2rem !important;
        width: 5.5rem !important;
        display: flex;
        flex-wrap: wrap;
        white-space: normal;
        margin: auto;
        align-content: flex-start !important;
      }
      .v-btn:is(.toggle) {
        h4 {
          margin-top: 6px;
        }
      }
      .v-btn-toggle {
        margin: 5% auto;
      }
      .v-btn-toggle > .v-btn.v-btn:first-child {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }
      .v-btn-toggle > .v-btn.v-btn:last-child {
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .below-header {
      h3.question {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: 960px) {
    .life-stages {
      &.life-stages-slider {
        .v-slider {
          height: 11em;

          &:after {
            height: 9em;
            left: -1.05em;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1264px) {
    .life-stages {
      &.life-stages-slider {
        .v-slider {
          height: 13.5em;

          &:after {
            height: 11.7em;
            left: -1.4em;
          }
        }
      }
    }
  }
</style>
