<template>
  <v-container class="below-header investor-matches">
    <h2 style="color: white; margin: 0 0 1em">
      {{ resultsTitle }}
      <router-link to="/" v-if="!search">
        <v-btn class="highlight">Edit my Selections</v-btn>
      </router-link>
    </h2>
    <h3
      v-if="this.search == 'favorites' && !appState.loggedIn"
      style="height: 800px; color: white; margin: 1em 0"
    >
      You must be logged in to view your favorite advisors.
    </h3>

    <h3
      v-else-if="
        this.search == 'favorites' &&
        appState.loggedIn &&
        !user.favorite_advisors.length
      "
      style="height: 800px; color: white; margin: 1em 0"
    >
      You do not have any favorite advisors selected.
    </h3>

    <template v-else>
      <v-chip-group
        column
        multiple
        v-model="calculated.matchFilter"
        @change="matchAdvisors()"
        v-if="!search && advisors.length"
      >
        <template v-for="attrib in aboutMe">
          <v-chip
            v-if="attrib.type != 'householdIncome'"
            :key="attrib.id"
            :value="attrib.id"
          >
            <img
              :src="`/img/icons/icon-${attrib.slug}.svg`"
              class="topic-icon"
            />
            <span>{{ attrib.title }}</span>
            <v-icon class="selected" right>mdi-close-circle</v-icon>
          </v-chip>
        </template>
      </v-chip-group>

      <div v-if="processing" style="height: 800px">
        <v-progress-circular
          class="main-loading"
          indeterminate
          color="#f8d543"
          size="100"
          width="14"
          height="100%"
        >
          <span>InvestmentHelp.org</span>
        </v-progress-circular>
      </div>
      <h3
        v-else-if="!search && calculated.matchFilter.length < 2"
        style="height: 800px; color: white; margin: 1em 0"
      >
        Please enable at least one attribute above.
      </h3>
      <h3
        v-else-if="!advisorList.length && this.search != 'favorites'"
        style="height: 800px; color: white; margin: 1em 0"
      >
        <span v-if="search">{{ data.settings.noResultsText }}</span>
        <span v-else>{{ data.settings.noMatchesText }}</span>
      </h3>

      <template v-else>
        <advisor-card :advisors="advisorList"></advisor-card>
      </template>

      <div
        class="match-pagination text-center"
        v-if="paginationLength && (search || calculated.matchFilter.length > 1)"
      >
        <v-pagination
          v-model="page"
          :length="paginationLength"
          :total-visible="7"
          @previous="getPage()"
          @next="getPage()"
          @input="getPage()"
        ></v-pagination>
      </div>
    </template>
  </v-container>
</template>

<script>
  import hasura from '../services/hasura'
  import axios from 'axios'
  import { getDistance } from 'geolib'
  import usZips from 'us-zips'

  // COMPONENTS
  import AdvisorCard from '@/components/AdvisorCard'

  export default {
    name: 'InvestorMatches',
    components: { AdvisorCard },
    data() {
      return {
        page: 1,
        paginationLength: 0,
        advisorList: [],
        matchedAdvisors: [],
        advisors: [],
        search: this.$route.query.search,
        searchZip: this.$route.query.searchZip,
        processing: false,
      }
    },
    computed: {
      limit() {
        if (this.search) {
          return 1000
        } else {
          return this.data.settings.advisorsPerPage
        }
        // return this.data.settings.advisorsPerPage
      },
      offset() {
        return (this.page - 1) * this.limit
      },
      resultsTitle() {
        const search = this.search
        if (search) {
          if (search == 'favorites') {
            return 'Your Favorite Advisors'
          } else {
            return 'Search Results - ' + search + ' ' + this.searchZip
          }
        } else
          return (
            'Investor Matches' +
            (this.data.settings.debugMode
              ? ` (${this.matchedAdvisors.length})`
              : '')
          )
      },
      isFavorites() {
        // set isFavorites if keyword is used in search
        return (
          this.search == 'favorites' && this.user.favorite_advisors.length > 0
        )
      },
    },
    methods: {
      setPage() {
        this.advisorList = []
        if (this.search || this.searchZip) {
          this.searchAdvisors()
        } else {
          this.initFilter()
          this.getAdvisors()
        }
        // window.scrollTo(0, 0)
      },
      rNum() {
        return Math.floor(Math.random() * 2) + 1
      },
      zipDistance(z1, z2) {
        // Returns lat long for the zip code
        const glic1 = usZips[z1]
        const glic2 = usZips[z2]
        if (glic1 && glic2)
          // get distance in meters and convert to miles rounding to 2nd decimal
          return Number((getDistance(glic1, glic2) * 0.000621371).toFixed(2))
        return undefined
      },
      initFilter() {
        this.calculated.matchFilter = []
        for (const attrib of this.aboutMe) {
          this.calculated.matchFilter.push(attrib.id)
        }
      },
      getPage() {
        if ((this.search || this.searchZip) && !this.isFavorites) {
          this.searchAdvisors()
        } else {
          // build about me pagination
          // let limit = this.limit
          // let offset = (this.page - 1) * limit
          this.advisorList = this.matchedAdvisors.slice(
            this.offset,
            this.offset + this.limit
          )

        //console.log(this.advisorList)

        }
        this.scrollToTop()
      },

      searchAdvisors() {
        this.processing = true
        // Search for all advisors that match criteria
        let criteria = {
          offset: this.offset,
          limit: this.limit,
        }
        let where = ''

        // search for favorite advisors
        if (this.isFavorites) {
          where = `{cst_key: {_in: ${JSON.stringify(
            this.user.favorite_advisors
          )}}}`
        }
        // search all advisor solely on zip codes
        else if (!this.search && this.searchZip) {
          console.log('No advisor name given but zip code given')
          // GraphQL query with null should allow to query all but this return an error
          // where = null
        }
        // otherwise search by advisor name
        else {
          let words = this.search.trim().split(' ')

          // build up where criteria
          // if two words used assume first and last name
          if (words.length == 2) {
            where = `{ _and: [`

            where += `
              {first_name: {_ilike: "${words[0]}%"}},
              {last_name: {_ilike: "${words[1]}%"}},
            `
          } else {
            where = `{ _and: [{ _or: [`

            // search first and last name for each word
            for (const word of words) {
              where += `
                {first_name: {_ilike: "${word}%"}},
                {last_name: {_ilike: "${word}%"}},
              `
            }

            where += `]},`
          }
          // add search for certified advisors only and wrap up where var
          where += `
            { _or: [
              {cima_status: {_eq: "Certified"}},
              {cpwa_status: {_eq: "Certified"}},
              {rma_status: {_eq: "Certified"}}
            ]}
          ]}`
        }

        criteria['where'] = where

        // search hasura for advisors based on criteria
        hasura
          .getAdvisors(criteria)
          .then((results) => {
            if (this.isFavorites) {
              this.advisors = results.data.advisors
              this.matchAdvisors()
            } else {
              const data = results.data
              this.paginationLength = Math.round(
                data.advisors_aggregate.aggregate.count / this.limit
              )
              // Filter based on zip code
              if (this.searchZip) {
                this.advisorList = data.advisors.filter((advisor) => {
                  return (
                    this.zipDistance(advisor.postal_code, this.searchZip) < 10
                  )
                })
              } else {
                this.advisorList = data.advisors
              }
              this.paginationLength = Math.round(
                this.advisorList.length / this.limit
              )

              this.processing = false
            }
          })
          .catch((error) => {
            this.processing = false
            console.log('err', error)
            // reject(error)
          })
      },

      getAdvisors() {
        this.processing = true
        // get all advisors that are currently seeking clients and match to investors about me
        let criteria = {}
        // advisors must be seeking and certified
        criteria['where'] = `
          { _and: [
            {seeking_new_clients: {_neq: "false"}},
            {seeking_new_clients: {_neq: "Not Seeking"}},
            { _or: [
              {cima_status: {_eq: "Certified"}},
              {cpwa_status: {_eq: "Certified"}},
              {rma_status: {_eq: "Certified"}},
            ]}
          ]}
        `

        // search hasura for advisors looking for clients
        hasura
          .getAdvisors(criteria)
          .then((results) => {
            this.advisors = results.data.advisors
            this.matchAdvisors()
          })
          .catch((error) => {
            this.processing = false
            console.log('err', error)
            // reject(error)
          })
      },

      matchAdvisors() {
        this.matchedAdvisors = []

        // build list of certifications the investor wants to match to
        const invAdvCerts = []
        for (const desiredCert of this.user.profile.advisorCertificate) {
          const cert_status = this.data.advCertById[desiredCert]
          if(cert_status) {
            invAdvCerts.push(cert_status)
          }
          
        }

        // build up matched advisors array by looping through all advisors and applying business logic
        for (const advisor of this.advisors) {
          advisor['scores'] = {
            lifeStage: 0,
            investableWealth: 0,
            preferredAttributes: 0,
            nearby: 0,
            lifeEvents: 0,
            importantFactors: 0,
            total: 0,
          }

          // If not favorite advisor, set lifestage distance and min wealth
          if (!this.isFavorites) {
            // If advisor does not have the desired certification, don't add advisor
            var qualified = true
            for (const invAdvCert of invAdvCerts ) {
              if (advisor[invAdvCert] != 'Certified') qualified = false
            }
            if (!qualified) continue


            // Set Life Stage ranking
            if (this.user.profile.lifeStageId) {
              const advLifeStage = advisor.client_life_stage

              // if filter removed, set match score to 1
              if (
                !this.calculated.matchFilter.includes(
                  this.user.profile.lifeStageId
                )
              )
                advisor.scores.lifeStage = 1
              // if advisor targets any life stage, then return score of 1
              else if (advLifeStage == 'Any') {
                advisor.scores.lifeStage = this.data.settings.lifeStageAnyScore
              }
              // otherwise calculate score or remove advisor if no match
              else {
                // set base life stage score and merge in multipliers
                let scores = [
                  ...[1],
                  ...this.data.settings.lifeStageGapMultiplier,
                ]

                let invIndex = this.data.invLifeStages.indexOf(
                  this.user.profile.lifeStageId
                )
                let advIndex = this.data.advLifeStages.indexOf(advLifeStage)
                let gap = Math.abs(invIndex - advIndex)

                // if advisor is outside of match range, don't add advisor
                if (gap >= this.data.settings.lifeStageGapMultiplier.length + 1)
                  continue

                // otherwise set lifeStage score for advisor
                advisor.scores.lifeStage = scores[gap]
              }
            }

            // Set Investable Wealth ranking
            const advMinInvWealth = advisor.client_min_invest_wealth
            if (this.user.profile.investableWealth && advMinInvWealth) {
              // remove advisor if below investor min invest wealth requirement
              let invIndex = this.data.invInvestableWealth.indexOf(
                this.user.profile.investableWealth
              )
              let advIndex =
                this.data.advInvestableWealth.indexOf(advMinInvWealth)
              let gap = invIndex - advIndex

              // if filter removed, set match score to 1
              if (
                !this.calculated.matchFilter.includes(
                  this.user.profile.investableWealth
                )
              )
                advisor.scores.investableWealth = 1
              // if investor has $50K and advisor allows <$100k or gap is 0 then set match score
              else if ((invIndex == 0 && advIndex == 1) || gap == 0)
                advisor.scores.investableWealth = 1
              // if investor doens't meet minimim value, then don't add advisor
              else if (gap < 0) continue
              // otherwise calculate match score based on gap
              else {
                advisor.scores.investableWealth =
                  1 - gap * this.data.settings.investableWealthGapMultiplier
              }
            }
          }
          // Set ranking for lifeEvents, importantFactors & preferredAttributes
          // let nearbyScore = this.data.settings.nearbyScore - MOVED TO GLOBAL
          let prefTotal = 0
          let prefMatches = 0
          let lifeEventsScore = 0
          let lifeEventsMaxScore = 0
          let importantFactorsScore = 0
          let importantFactorsMaxScore = 0
          let hasCIMA = advisor.cima_status == 'Certified'
          // let hasCFP = advisor.cfp_status == 'Certified'
          let hasCPWA = advisor.cpwa_status == 'Certified'
          let hasRMA = advisor.rma_status == 'Certified'
          for (const attrib of this.aboutMe) {
            // Nearby scoring
            if (attrib.slug == 'nearby') {
              // prefTotal++
              const glic1 = usZips[this.user.postal_code]
              const glic2 = usZips[advisor.postal_code.slice(0, 5)]

              // if filter removed, set attribute score to 1
              if (!this.calculated.matchFilter.includes(attrib.id))
                advisor.scores['nearby'] = -1
              else if (glic1 && glic2) {
                prefMatches++

                const distance = Number(
                  (getDistance(glic1, glic2) * 0.000621371).toFixed(2)
                )
                // advisor.scores['nearby'] = 0

                const calculatedNearbyScore =
                  distance < this.data.settings.nearbyMaxDistance
                    ? this.nearbyScore
                    : this.nearbyScore /
                      (distance / this.data.settings.nearbyMaxDistance)

                // add calculated nearby score to advisor
                advisor.scores['nearby'] = calculatedNearbyScore
              }
            }

            // Matching and scoring preferredAttributes
            if (attrib.type == 'preferredAttributes') {
              prefTotal++

              // if filter removed, set increment attribute pref match
              if (!this.calculated.matchFilter.includes(attrib.id))
                prefMatches++
              else if (advisor.race_ethnicity == attrib.netforumValues) {
                prefMatches++
              } else if (
                advisor.gender_identity &&
                attrib.netforumValues.indexOf(advisor.gender_identity) > -1
              ) {
                prefMatches++
              } else if (
                advisor.conduct_virtual_advising &&
                attrib.netforumValues.indexOf(
                  advisor.conduct_virtual_advising
                ) > -1
              ) {
                prefMatches++
              }
            }

            // Matching and scoring lifeEvents
            if (attrib.type == 'lifeEvents') {
              // if filter removed, set life events attributes to 1
              if (!this.calculated.matchFilter.includes(attrib.id)) {
                lifeEventsScore = lifeEventsMaxScore = 1
              } else {
                // get max cert score for all advisor certs
                const allAdvCertsHighScore = Math.max(
                  hasCIMA ? attrib.cimaScore : 0,
                  // hasCFP ? attrib.cfpScore : 0,
                  hasCPWA ? attrib.cpwaScore : 0,
                  hasRMA ? attrib.rmaScore : 0
                )
                // add hightest cert score to lifeEventsScore
                lifeEventsScore += allAdvCertsHighScore
                // add max cert score to lifeEventsMaxScore
                lifeEventsMaxScore += attrib.maxCertScore
              }
            }

            // Matching and scoring importantFactors
            if (attrib.type == 'importantFactors') {
              // if filter removed, set important factors attributes to 1
              if (!this.calculated.matchFilter.includes(attrib.id)) {
                importantFactorsScore = importantFactorsMaxScore = 1
              } else {
                // get max cert score for all advisor certs
                const allAdvCertsHighScore = Math.max(
                  hasCIMA ? attrib.cimaScore : 0,
                  // hasCFP ? attrib.cfpScore : 0,
                  hasCPWA ? attrib.cpwaScore : 0,
                  hasRMA ? attrib.rmaScore : 0
                )
                // add hightest cert score to importantFactorsScore
                importantFactorsScore += allAdvCertsHighScore
                // add max cert score to importantFactorsMaxScore
                importantFactorsMaxScore += attrib.maxCertScore
              }
            }
          }

          // set calculated preferredAttributes score
          if (prefTotal)
            advisor.scores.preferredAttributes =
              (prefMatches / prefTotal) *
              (advisor.scores.nearby > -1 ? advisor.scores.nearby : 1)

          // set calculated lifeEvents score
          if (lifeEventsMaxScore)
            advisor.scores.lifeEvents = lifeEventsScore / lifeEventsMaxScore

          // set calculated importantFactors score
          if (importantFactorsMaxScore)
            advisor.scores.importantFactors =
              importantFactorsScore / importantFactorsMaxScore

          // calculate advisor total score
          advisor.scores.total =
            (advisor.scores.lifeStage * this.data.settings.lifeStageWeight +
              advisor.scores.investableWealth *
                this.data.settings.investableWealthWeight +
              advisor.scores.preferredAttributes *
                this.data.settings.preferencesWeight +
              advisor.scores.lifeEvents * this.data.settings.lifeEventsWeight +
              advisor.scores.importantFactors *
                this.data.settings.importantFactorsWeight) /
            (this.data.settings.lifeStageWeight +
              this.data.settings.investableWealthWeight +
              this.data.settings.preferencesWeight +
              this.data.settings.lifeEventsWeight +
              this.data.settings.importantFactorsWeight)

          // Weight premium member
          if (this.isPremiumAdvisor(advisor))
            advisor.scores.total =
              advisor.scores.total * (1 + this.data.settings.premiumMemberBonus)

          // Weight advisor actively seeking advisors
          if (advisor.seeking_new_clients == 'Actively Seeking')
            advisor.scores.total =
              advisor.scores.total *
              (1 + this.data.settings.activelySeekingBonus)

          // add advisor matchIds array
          advisor['matchIds'] = []

          // add advisor to matched advisor array if rating greater than investor match threshold or isFavorite
          if (
            advisor.scores.total > this.data.settings.investorMatchThreshold ||
            this.data.settings.investorMatchThreshold == 0 ||
            this.isFavorites
          )
            this.matchedAdvisors.push(advisor)
        }

        // sort the advisors
        this.matchedAdvisors.sort((a, b) =>
          a.scores.total < b.scores.total ? 1 : -1
        )

        // truncate advisors array
        const maxMatchedLength = this.data.settings.maximumMatchedAdvisors
        const matchedLength = this.matchedAdvisors.length
        if (maxMatchedLength > 0 && matchedLength > maxMatchedLength)
          this.matchedAdvisors.length = maxMatchedLength

        this.paginationLength = Math.round(
          this.matchedAdvisors.length / this.limit
        )

        this.processing = false

        // add advisors to page
        this.getPage()
      },
    },

    updated() {
      // console.log('INVESTOR_MATCHES_UPDATED')
    },
    mounted() {
      this.setPage()
    },
    watch: {
      '$route.query.search'() {
        this.search = this.$route.query.search
        this.setPage()
      },
      '$route.query.searchZip'() {
        this.searchZip = this.$route.query.searchZip
        this.setPage()
      },
      'appState.loggedIn'() {
        this.setPage()
      },
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  h2 {
    .v-btn {
      padding: 0.75em 2em !important;
      .v-btn__content {
        font-family: @font-family-base;
        letter-spacing: 0;
        text-transform: none;
      }
    }
  }
  .below-header.investor-matches {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: @text-color;
    }

    .v-chip {
      background-color: #c1c1c1;

      &:before {
        opacity: 0;
      }

      .v-icon {
        transform: rotate(45deg);
      }

      &.v-chip--active {
        background-color: white;

        span {
          color: @text-color;
        }

        .v-icon {
          transform: rotate(360deg);
        }

        .topic-icon {
          & + span {
            border-color: @brand-yellow;
          }
        }
      }

      .topic-icon {
        height: 1.5em;
        margin-left: -0.3333333em;

        & + span {
          padding-right: 1em;
          margin-left: 0.5em;
          padding-left: 0.5em;
          border-left: solid white 1px;
        }
      }
    }
    .v-chip:focus-within,
    .v-chip:hover {
    }

    .big-screen {
      display: none;
    }

    .match-pagination {
      margin-top: 3em;
    }

    @media screen and (min-width: 600px) {
      .big-screen {
        display: block;

        h6 {
          text-transform: uppercase;
        }
      }

      .small-screen {
        display: none;
      }
    }
  }
</style>
