import Vue from 'vue'
import Router from 'vue-router'
import InvestorLifeStage from './views/InvestorLifeStage.vue'
import InvestorFinances from './views/InvestorFinances.vue'
import InvestorPreferences from './views/InvestorPreferences.vue'
import InvestorSituation from './views/InvestorSituation.vue'
import InvestorPriorities from './views/InvestorPriorities.vue'
import InvestorMatches from './views/InvestorMatches.vue'
import AdvisorProfile from './views/AdvisorProfile.vue'
import AdvisorLanding from './views/AdvisorLanding.vue'
import AdvisorDetail from './views/AdvisorDetail.vue'
import ResourceLanding from './views/ResourceLanding.vue'
import Resource from './views/Resource.vue'
import Certification from './views/Certification.vue'
import NotFound from './views/NotFound.vue'

Vue.use(Router)

export default new Router({
  scrollBehavior() {
    return { x: 0, y: 0 }
  },

  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'InvestorLifeStage',
      component: InvestorLifeStage,
      meta: {
        title: 'Life Stage',
      },
    },
    {
      path: '/life-stage',
      name: 'InvestorLifeStage',
      component: InvestorLifeStage,
      meta: {
        title: 'Life Stage',
      },
    },
    {
      path: '/investor-finances',
      name: 'InvestorFinances',
      component: InvestorFinances,
      meta: {
        title: 'Finances',
      },
    },
    {
      path: '/investor-preferences',
      name: 'InvestorPreferences',
      component: InvestorPreferences,
      meta: {
        title: 'Preferences',
      },
    },
    {
      path: '/investor-situation',
      name: 'InvestorSituation',
      component: InvestorSituation,
      meta: {
        title: 'Situation',
      },
    },
    {
      path: '/investor-priorities',
      name: 'InvestorPriorities',
      component: InvestorPriorities,
      meta: {
        title: 'Priorities',
      },
    },
    {
      path: '/investor-matches',
      name: 'InvestorMatches',
      component: InvestorMatches,
      meta: {
        title: 'Matches',
      },
    },
    {
      path: '/advisor/profile/',
      name: 'AdvisorProfile',
      component: AdvisorProfile,
      meta: {
        title: 'Profile',
      },
    },
    {
      path: '/advisor/detail/:cstNo',
      name: 'AdvisorDetail',
      component: AdvisorDetail,
      meta: {
        title: 'Detail',
      },
    },
    {
      path: '/for-advisors/',
      name: 'AdvisorLanding',
      component: AdvisorLanding,
      meta: {
        title: 'Landing',
      },
    },
    {
      path: '/resources/',
      name: 'Resources',
      component: ResourceLanding,
      meta: {
        title: 'Resources',
      },
    },
    {
      path: '/resource/:resourceSlug',
      name: 'Resource',
      component: Resource,
      meta: {
        title: 'Resource',
      },
    },
    {
      path: '/certification/:certificationSlug',
      name: 'Certification',
      component: Certification,
      meta: {
        title: 'Certification',
      },
    },
    {
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: NotFound,
      meta: {
        title: 'Not Found',
      },
    },
  ],
})
