<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn
        :value="value"
        class="toggle"
        :class="[
          standalone ? 'toggle-standalone' : '',
          icon ? 'toggle-icon' : '',
        ]"
        rounded
        x-large
        v-on="on"
        :disabled="disabled"
      >
        <div class="white-square"></div>

        <svg-img :svgUrl="icon" v-if="icon" class="topic-icon"></svg-img>
        <div v-if="icon" class="dotted-line"></div>

        <span class="phrase" v-html="phrase"></span>
        <v-icon class="selected" right>mdi-check</v-icon>
      </v-btn>
    </template>
    <span v-if="definition">
      {{ definition }}
    </span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'ToggleButton',
    components: {},
    props: {
      phrase: String,
      definition: String,
      icon: String,
      status: Boolean,
      value: String,
      standalone: Boolean,
      disabled: Boolean,
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
  .white-square {
    display: none;
    height: 4em;
    width: 4em;
    background-color: @brand-white;
    overflow: hidden;
    // margin-right: 70%;
    position: absolute;
    z-index: -1;
  }

  .theme--light.v-btn.v-btn--disabled {
    color: inherit !important;
    &.v-btn--has-bg {
      background-color: white !important;
    }
  }

  // Base Toggle Button
  .toggle {
    width: 340px;
  }
  .v-btn:is(.toggle),
  .v-btn-toggle > .v-btn.v-btn,
  .v-btn:is(.icon-toggle.toggle) {
    font-family: @font-family-serif;
    font-weight: 400;
    font-size: 1rem;
    opacity: 1;
    border-width: 0;
    // width: 340px;

    &.toggle-standalone {
      justify-content: flex-start;
      .v-btn__content {
        white-space: normal;
        max-width: 100%;
        text-align: left;
      }

      &.v-item--active.toggle-icon {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 3.9em;
          background-color: white;
          border-top-left-radius: 50%;
          border-bottom-left-radius: 50%;
          z-index: 0;
        }
      }
    }

    .v-btn__content {
      text-transform: none;
      letter-spacing: 0;
      padding: 0 3.5%;

      .v-icon.drawer-icon,
      .drawer-icon {
        position: absolute;
        top: -1.25em;
        background-color: @brand-white;
        border: solid @brand-yellow 2px;
        border-radius: 50%;
        padding: 0.25em;
        display: none;
        height: 2em;
        width: 2em;
        font-size: 24px;
        transform: scale(0.7);
      }

      .topic-icon {
        min-width: 2.5em;
        width: 2.5em;
        margin-left: -1em;
        margin-right: 0.5em;
        flex-shrink: 0;
        z-index: 1;
      }

      .dotted-line {
        content: '';
        height: 37px;
        width: 12px;
        border-left: 1px solid @brand-yellow;
      }
      .phrase {
        max-width: 180px;
      }
    }

    &.v-btn--active {
      &::before {
        background-color: @brand-yellow;
        opacity: 1;
      }
      .v-btn__content {
        .v-icon.drawer-icon,
        .drawer-icon {
          display: block;
        }
      }
      .white-square {
        display: block;
      }
    }
  }

  .icon-toggle.toggle-select button.v-btn--active {
    &::before {
      height: 108%;
      top: -8%;
    }
  }

  .toggle {
    .selected {
      display: flex;
      visibility: hidden;
    }
    &.v-item--active {
      .selected {
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: 600px) {
    // Base Toggle Button
    .v-btn:is(.toggle),
    .v-btn-toggle > .v-btn.v-btn,
    .v-btn:is(.icon-toggle.toggle) {
      width: 300px;
      &.toggle-standalone {
        &.v-item--active {
          &:after {
            width: 4.1em;
          }
        }
      }

      .v-btn__content {
        padding: 0;

        .v-icon.drawer-icon,
        .drawer-icon {
          top: -2.25em !important;
          transform: scale(1);
        }

        .topic-icon {
          margin-left: -0.3333em;
        }
      }
    }

    .icon-toggle.toggle-select button.v-btn--active {
      &::before {
        height: 120%;
        top: -20%;
      }
    }
  }
</style>
