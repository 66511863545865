<template>
  <v-footer>
    <v-container>
      <nav>
        <v-row v-if="!appState.minWrap">
          <v-col cols="12" sm="4" md="3">
            <a
              href="https://investmentsandwealth.org/investors"
              target="_blank"
            >
              <svg-img
                svgUrl="/img/iwi-logo-square.svg"
                title="Investments and Wealth Institute logo"
                class="footer-logo"
              />
            </a>
          </v-col>
          <v-col cols="12" sm="4">
            <h3>For Investors</h3>
            <ul>
              <li>
                <router-link to="/investor-matches?search=favorites">
                  My Favorite Advisors
                </router-link>
                <v-icon color="#f25d07">mdi-heart</v-icon>
              </li>
              <li>
                <router-link to="/investor-matches">My Matches</router-link>
              </li>
              <li><a href="/resources">Resources and Education</a></li>
              <li>
                <a href="/resource/about-the-investments-wealth-institute">
                  About the Investments & Wealth Institute
                </a>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" sm="4" md="5">
            <h3>For Advisors</h3>
            <ul>
              <li>
                <a
                  href="https://investmentsandwealth.org/login"
                  target="_blank"
                >
                  Log In to Manage Your Profile
                </a>
                <v-icon x-small color="#ffffff">mdi-open-in-new</v-icon>
              </li>
              <li>
                <router-link to="/for-advisors">
                  Membership and Listing Opportunities
                </router-link>
              </li>
              <li>
                <a
                  href="https://investmentsandwealth.org/certifications/which-program-is-right-for-you"
                  target="_blank"
                >
                  Certifications
                </a>
                <v-icon x-small color="#ffffff">mdi-open-in-new</v-icon>
              </li>
              <li>
                <a href="https://investmentsandwealth.org/home" target="_blank">
                  Investments & Wealth Institute
                </a>
                <v-icon x-small color="#ffffff">mdi-open-in-new</v-icon>
              </li>
            </ul>
          </v-col>
        </v-row>
      </nav>

      <v-row>
        <v-col cols="12" md="6">
          <h3 class="question">Who is listed on InvestmentHelp.org?</h3>
          <p>
            Financial advisors listed on InvestmentHelp.org have completed one
            or more advanced certifications (CIMA®, CPWA® or RMA®) from the
            Investments & Wealth Institute and/or have a CFP® certification and
            have elected to be listed.
          </p>
        </v-col>
        <v-col cols="12" md="6" class="col2">
          <h3 class="question">Why should I trust InvestmentHelp.org?</h3>
          <p>
            InvestmentHelp.org is a free tool for investors. We do not require
            you to share your contact information in order to match to qualified
            financial advisors. Advisors who are certified by the Investments &
            Wealth Institute hold some of the most respected credentials in the
            financial services industry and are required to adhere to the
            Investments & Wealth Institute's
            <em>Code of Professional Responsibility</em>
            .
          </p>
        </v-col>
      </v-row>
    </v-container>
    <div class="legal">
      <ul>
        <li>
          &copy;{{ new Date().getFullYear() }} Investments &amp; Wealth
          Institute
        </li>
        <li>
          <a
            href="https://investmentsandwealth.org/about-us/privacy-policy"
            target="_blank"
          >
            Privacy Policy &amp; Conditions of Use
          </a>
          <v-icon x-small color="#ffffff">mdi-open-in-new</v-icon>
        </li>
        <li>
          <router-link to="/resource/disclosures">Disclosures</router-link>
        </li>
      </ul>
    </div>
  </v-footer>
</template>

<script>
  // import HelpTip from '@/components/HelpTip'

  export default {
    name: 'AppFooter',
    // components: { HelpTip },
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  footer {
    &.theme--light.v-footer {
      padding-top: 1.5em;
      background-color: @brand-dark-blue;

      h3 {
        color: @brand-yellow;
        font-size: @font-size-upbit;
        margin: 0.888em 0;
      }

      p,
      a {
        color: white;
      }

      ul,
      li {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      li {
        padding: 0.25em 0;
      }

      svg {
        polygon,
        path,
        rect {
          &.cls-3,
          &.cls-4 {
            fill: white;
          }
        }
      }

      .footer-logo {
        width: 13em;
        margin-top: 0.75em;
      }

      .legal {
        display: flex;
        justify-content: center;
        padding-bottom: 1em;
        color: @brand-white;
        width: 100%;
        font-size: @font-size-mini;

        ul {
          display: flex;
          flex-direction: row;

          li {
            margin: 1em;
            border-right: solid @brand-white 1px;
            padding: 0;
            padding-right: 1em;
            line-height: 1.2em;
          }
          li:last-of-type {
            border-width: 0;
            margin-left: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: 960px) {
    .col2 {
      padding-left: 5em;
    }
  }
</style>
