<template>
  <v-container class="below-header situation">
    <h2>What is going on in your life right now?</h2>
    <v-card
      v-scroll.self="onScroll"
      :class="[$vuetify.breakpoint.smAndDown ? 'overflow-y-auto' : 'card']"
      max-height="250"
      color="transparent"
      elevation="0"
      id="scroll-card"
    >
      <h3 class="question">
        Are there big events happening in your life now or soon?
      </h3>
      <p class="directions">Choose as many as you need help managing.</p>

      <v-btn-toggle
        multiple
        v-model="user.profile.lifeEvents"
        @change="$store.dispatch('updateInvestorProfile')"
        :value-comparator="simpleMatch"
      >
        <div class="button-group">
          <toggle-button
            v-for="event in data.lifeEvents"
            :key="event.id"
            :value="event.id"
            :phrase="event.title"
            :definition="event.definition"
            :icon="`/img/icons/icon-${event.slug}.svg`"
            standalone
          ></toggle-button>
        </div>
      </v-btn-toggle>
    </v-card>

    <match-navigation :navProgress="progressIndex"></match-navigation>
  </v-container>
</template>

<script>
  import MatchNavigation from '@/components/MatchNavigation'
  import ToggleButton from '@/components/ToggleButton'

  export default {
    name: 'InvestorSituation',
    components: { MatchNavigation, ToggleButton },
    data() {
      return {}
    },
    computed: {
      progressIndex() {
        const index = this.data.settings.matchingPageOrder.findIndex(x => x.stepSlug === 'investor-situation')
        return index+1
      }
    },
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
  .situation {
    .card {
      margin-bottom: 8.2rem;
    }
  }
  @media screen and (max-width: 599px) {
    .situation {
      h3.question {
        margin: 1rem 0;
      }
      h2 {
        margin-top: -1.3rem;
      }
    }
  }
</style>
