<template>
  <div class="unset-style">
    <div class="arrow-upward"></div>
    <v-card
      class="profile-menu overflow-y-auto"
      v-scroll.self="onScroll"
      max-height="500"
    >
      <v-list v-if="appState.loggedIn">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h3>Hi {{ user.first_name }},</h3>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <p>
              <router-link to="/investor-matches?search=favorites">
                See your favorite advisors
              </router-link>
              <v-icon color="#F25D07">mdi-heart</v-icon>
            </p>
            <p>
              <router-link to="/investor-matches">
                See your advisor matches
              </router-link>
            </p>
          </v-list-item-content>
          <v-list-item-content class="list-button">
            <v-btn class="highlight" @click="logout()">Log Out</v-btn>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><h4>Location</h4></v-list-item-title>
            <v-list-item-content>
              <v-text-field
                clearable
                outlined
                v-model="user.postal_code"
                hide-details
                placeholder="ZIP Code"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h4>My Life Now</h4>
              <router-link to="/">Change</router-link>
            </v-list-item-title>
            <v-list-item-content v-if="myLife.length">
              <v-chip-group column>
                <v-chip v-for="attrib in myLife" :key="'p' + attrib.id">
                  <img
                    :src="`/img/icons/icon-${attrib.slug}.svg`"
                    class="topic-icon"
                  />
                  <span>{{ attrib.title }}</span>
                </v-chip>
              </v-chip-group>
            </v-list-item-content>
            <v-list-item-content v-else>No Selections</v-list-item-content>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h4>My Finances</h4>
              <router-link to="/investor-finances">Change</router-link>
            </v-list-item-title>
            <v-list-item-content v-if="myFinances.length">
              <v-chip-group column>
                <v-chip v-for="attrib in myFinances" :key="'p' + attrib.id">
                  <img
                    :src="`/img/icons/icon-${attrib.slug}.svg`"
                    class="topic-icon"
                  />
                  <span>{{ attrib.title }}</span>
                </v-chip>
              </v-chip-group>
            </v-list-item-content>
            <v-list-item-content v-else>No Selections</v-list-item-content>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h4>My Preferences</h4>
              <router-link to="/investor-preferences">Change</router-link>
            </v-list-item-title>
            <v-list-item-content v-if="myPreferences.length">
              <v-chip-group column>
                <v-chip v-for="attrib in myPreferences" :key="'p' + attrib.id">
                  <img
                    :src="`/img/icons/icon-${attrib.slug}.svg`"
                    class="topic-icon"
                  />
                  <span>{{ attrib.title }}</span>
                </v-chip>
              </v-chip-group>
            </v-list-item-content>
            <v-list-item-content v-else>No Selections</v-list-item-content>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h4>My Situation</h4>
              <router-link to="/investor-situation">Change</router-link>
            </v-list-item-title>
            <v-list-item-content v-if="mySituation.length">
              <v-chip-group column>
                <v-chip v-for="attrib in mySituation" :key="'p' + attrib.id">
                  <img
                    :src="`/img/icons/icon-${attrib.slug}.svg`"
                    class="topic-icon"
                  />
                  <span>{{ attrib.title }}</span>
                </v-chip>
              </v-chip-group>
            </v-list-item-content>
            <v-list-item-content v-else>No Selections</v-list-item-content>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h4>My Priorities</h4>
              <router-link to="/investor-priorities">Change</router-link>
            </v-list-item-title>
            <v-list-item-content v-if="myPriorities.length">
              <v-chip-group column>
                <v-chip v-for="attrib in myPriorities" :key="'p' + attrib.id">
                  <img
                    :src="`/img/icons/icon-${attrib.slug}.svg`"
                    class="topic-icon"
                  />
                  <span>{{ attrib.title }}</span>
                </v-chip>
              </v-chip-group>
            </v-list-item-content>
            <v-list-item-content v-else>No Selections</v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list v-else-if="appState.setUpAccount">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Create an Account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-form ref="createForm" v-model="formValid" class="login-form">
              <v-text-field
                autofocus
                clearable
                outlined
                placeholder="Email"
                label="Email"
                v-model="credentials.email"
                :rules="RULES.email"
                @keyup.enter="createAccount()"
                tabindex="1"
              ></v-text-field>
              <div class="first-last">
                <v-text-field
                  clearable
                  outlined
                  placeholder="First Name"
                  label="First Name"
                  v-model="credentials.firstName"
                  :rules="RULES.required"
                  @keyup.enter="createAccount()"
                  tabindex="2"
                ></v-text-field>
                <v-text-field
                  clearable
                  outlined
                  placeholder="Last Name"
                  label="Last Name"
                  v-model="credentials.lastName"
                  :rules="RULES.required"
                  @keyup.enter="createAccount()"
                  tabindex="3"
                ></v-text-field>
              </div>
              <v-text-field
                outlined
                placeholder="Password"
                label="Password"
                v-model="credentials.pwd"
                :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="RULES.pwd"
                :type="showPwd ? 'text' : 'password'"
                @click:append="showPwd = !showPwd"
                @keyup.enter="createAccount()"
                tabindex="4"
              ></v-text-field>
              <p>
                Passwords must be between 6 and 14 characters in length, and
                must contain at least one numeric character
              </p>
              <v-text-field
                outlined
                placeholder="Confirm Password"
                label="Confirm Password"
                v-model="credentials.confirmPwd"
                :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="RULES.pwdMatch"
                :type="showPwd ? 'text' : 'password'"
                @click:append="showPwd = !showPwd"
                @keyup.enter="createAccount()"
                tabindex="5"
              ></v-text-field>
              <v-checkbox v-model="credentials.concent" :rules="RULES.required">
                <template v-slot:label>
                  <div>
                    I have read and concent to
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="https://investmentsandwealth.org/about-us/privacy-policy"
                          @click.stop
                          v-on="on"
                        >
                          IWI's privacy policies
                        </a>
                      </template>
                      Open new window
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </v-form>
            <div class="error" v-show="serverError">
              There was as issue creating your account. Please try again or try
              logging in.
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="list-button">
            <v-btn
              :disabled="!formValid || isLoggingIn"
              class="highlight"
              @click="createAccount()"
            >
              <span v-if="!isLoggingIn">Create Account</span>
              <v-progress-circular
                v-else
                indeterminate
                color="#f8d543"
              ></v-progress-circular>
            </v-btn>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title @click="gotoLoginForm()">
              <a>Already have an account? Login instead.</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list v-else>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Log In</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-form ref="loginForm" v-model="formValid" class="login-form">
              <v-text-field
                autofocus
                clearable
                outlined
                placeholder="Email"
                label="Email"
                v-model="credentials.email"
                :rules="RULES.email"
                @keyup.enter="login()"
                tabindex="1"
              ></v-text-field>

              <v-text-field
                outlined
                placeholder="Password"
                label="Password"
                v-model="credentials.pwd"
                :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="RULES.pwd"
                :type="showPwd ? 'text' : 'password'"
                @click:append="showPwd = !showPwd"
                @keyup.enter="login()"
                tabindex="2"
              ></v-text-field>
            </v-form>
            <div class="error" v-show="serverError">
              Your email or password are incorrect
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="list-button">
            <v-btn
              :disabled="!formValid || isLoggingIn"
              class="highlight"
              @click="login()"
            >
              <span v-if="!isLoggingIn">Sign In</span>
              <v-progress-circular
                v-else
                indeterminate
                color="#f8d543"
              ></v-progress-circular>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>
            <a
              href="https://investmentsandwealth.org/user-account/request-password-reset"
            >
              Forgot your password?
            </a>
          </v-list-item-subtitle>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title @click="gotoCreateForm()">
              <a>Don't have an account? Set one up!</a>
            </v-list-item-title>
            <v-list-item-subtitle>
              You can save your favorite advisors and save your matches
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'ProfileMenu',
    components: {},
    data() {
      return {
        RULES: {
          pwd: [
            (v) =>
              /^(?=.*\d)(?=.*[a-zA-Z]).{6,14}$/.test(v) ||
              'Password is required',
          ],
          pwdMatch: [
            (v) => v == this.credentials.pwd || 'Passwords must match',
          ],
        },
        credentials: {
          email: '',
          firstName: '',
          lastName: '',
          pwd: '',
          confirmPwd: '',
          concent: false,
        },
        showPwd: false,
        isLoggingIn: false,
        formValid: false,
        serverError: false,
      }
    },
    computed: {
      myLife() {
        return this.aboutMe.filter((attrib) => attrib.type == 'lifeStages')
      },
      myFinances() {
        return this.aboutMe.filter(
          (attrib) =>
            attrib.type == 'householdIncome' ||
            attrib.type == 'investableWealth'
        )
      },
      myPreferences() {
        return this.aboutMe.filter(
          (attrib) => attrib.type == 'preferredAttributes'
        )
      },
      mySituation() {
        return this.aboutMe.filter((attrib) => attrib.type == 'lifeEvents')
      },
      myPriorities() {
        return this.aboutMe.filter(
          (attrib) => attrib.type == 'importantFactors'
        )
      },
    },
    methods: {
      login() {
        if (this.formValid) {
          this.serverError = false
          this.isLoggingIn = true

          this.$store
            .dispatch('investorLogin', this.credentials)

            .then((response) => {
              this.isLoggingIn = false
              if (response.success) {
                // do something
              } else {
                this.serverError = true
              }
            })
            .catch((error) => {
              console.log('err', error)
            })
        }
      },

      createAccount() {
        if (this.formValid) {
          this.serverError = false
          this.isLoggingIn = true

          this.$store
            .dispatch('createInvestorAccount', this.credentials)

            .then((response) => {
              this.isLoggingIn = false
              if (response.success) {
                this.login()
              } else {
                this.serverError = true
              }
            })
            .catch((error) => {
              console.log('err', error)
            })
        }
      },

      logout() {
        this.$store.dispatch('investorLogout')
      },
      gotoCreateForm() {
        this.appState.setUpAccount = true
        this.$refs.loginForm.resetValidation()
      },
      gotoLoginForm() {
        this.appState.setUpAccount = false
        this.$refs.createForm.resetValidation()
      },
    },
    updated() {
      // console.log('PROFILE_UPDATED')
    },
    mounted() {
      // console.log('PROFILE_MOUNTED')
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
  .unset-style {
    all: unset;
  }
  .arrow-upward {
    line-height: 0;
    display: inline-block;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    margin-left: 3rem;
    height: 33px;
    width: 30px;
    margin-left: 80%;
    margin-bottom: -1em;
    background-color: white;
  }

  .profile-menu {
    background-color: white;
    padding: 1em;
    .v-list-item__title {
      display: flex;
      align-items: flex-end;
      width: -moz-fit-content;
      width: fit-content;

      h3 {
        color: @brand-dark-blue;
        font-size: @font-size-upbit;
      }

      h4 {
        color: @brand-dark-blue;
        font-size: @font-size-mini;
        font-family: @font-family-serif;
        text-transform: none;
        line-height: 1em;
      }

      a {
        font-size: @font-size-micro;
        text-transform: uppercase;
        padding-left: 0.7em;
      }
    }

    .v-list-item__content {
      p {
        line-height: 1.6em;
      }

      .v-input__slot {
        max-width: 24em;
      }
    }

    .v-item-group {
      .v-chip {
        background-color: white;
        border: solid @brand-light-gray 1px;

        .topic-icon {
          height: 1.5em;
          margin-left: -0.5em;
          width: 1.5em;

          & + span {
            padding-right: 1em;
            border-left: solid @brand-deep-yellow 1px;
            padding-left: 0.5em;
            margin-left: 0.5em;
          }
        }
      }
    }

    .list-button {
      flex-grow: 0.5;
      overflow: inherit;

      .v-btn {
        text-transform: none;
        padding: 0 !important;
      }
    }
    .v-text-field {
      width: 98%;
      max-width: 207px;
    }
    .first-last {
      width: 98%;
      display: flex;
      flex-direction: row;
      & div.v-input__slot {
        width: 82%;
      }
    }
  }
  @media screen and (min-width: 600px) {
    .profile-menu {
      .v-text-field {
        max-width: unset;
      }
    }
  }
</style>
