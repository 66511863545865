import axios from 'axios'
import { validate as uuidValidate } from 'uuid'
import JSON5 from 'json5'

// TODO: set to false for prod
const IS_DEV = true

const headers = {
  'content-type': 'application/json',
}

const hasura = (query) => {
  // if(IS_DEV) console.log('QUERY:', query)
  return axios({
    url: '/v1/graphql',
    method: 'post',
    headers: headers,
    data: query,
  })
}

const objToGql = (obj) => {
  return JSON5.stringify(obj, { quote: '"' })
}

export const getAdvisors = (criteria) => {
  if (IS_DEV) console.log('CRITERIA:', criteria)
  // determine criteria type
  let gqlOffsetLimit = ''
  let gqlWhere = ''
  if (criteria.cst_key) gqlWhere = `{ cst_key: { _eq: "${criteria.cst_key}" } }`
  else if (criteria.cst_no) gqlWhere = `{ cst_no: { _eq: ${criteria.cst_no} } }`
  else {
    gqlWhere = criteria.where
    if (criteria.offset > -1) gqlOffsetLimit += `offset: ${criteria.offset},`
    if (criteria.limit) gqlOffsetLimit += `limit: ${criteria.limit},`
  }
  if (IS_DEV) console.log('GQL_CRITERIA:', gqlWhere)

  const query = {
    operationName: 'getAdvisors',
    query: `query getAdvisors {
      advisors_aggregate(where: ${gqlWhere}) {
        aggregate {
          count
        }
      }
      advisors(${gqlOffsetLimit} order_by: {sort_by_field: asc}, where: ${gqlWhere}) {
        cst_key
        cst_no
        first_name
        last_name
        alias
        sort_by_field
        prefix
        race_ethnicity
        gender_identity
        cima_status
        cpwa_status
        education
        types_of_clients
        credentials
        experience
        assets_under_advisement
        professional_responsibilities
        client_invest_wealth
        disciplinary_history_text
        disciplinary_history
        lgbtq_ally
        client_min_invest_wealth
        profile_intro
        practice_description
        practice_postal_code
        rma_status
        title
        org_name
        phone
        email
        address_1
        address_2
        address_3
        city
        state
        postal_code
        country
        membership_key
        membership_type
        membership_status
        cima_date
        cpwa_date
        rma_date
        provided_services
        niche_services
        client_life_stage
        conduct_virtual_advising
        is_investor
        business_via_text
        seeking_new_clients
        sm_facebook
        sm_linkedin
        sm_twitter
        sm_flickr
        sm_youtube
        sm_slideshare
        sm_foursquare
        icons {
          file_uuid
          id
          link
          title
        }
        photos {
          caption
          file_uuid
          id
        }
        videos {
          caption
          id
          link
        }
      }
    }`,
  }
  if (IS_DEV) console.log('GET_ADVISORS_QUERY:', query)

  return new Promise((resolve, reject) => {
    hasura(query)
      .then((response) => {
        if (response.data.errors) {
          resolve({ data: response.data.errors, success: false })
        } else {
          const data = response.data.data
          if (IS_DEV) console.log('GET_ADVISORS:', data)
          resolve({ data: data, success: true })
        }
      })
      .catch((error) => {
        console.log('err', error)
        reject(error)
      })
  })
}

export default {
  getAdvisors,
}
