<template>
  <v-container class="below-header finances">
    <h2 style="color: white; margin: 0 0 1em">
      Let's learn about your finances
    </h2>
    <!-- mobile -->
    <v-container v-if="$vuetify.breakpoint.xsOnly" class="mobile">
      <!-- Income -->
      <v-card
        v-scroll.self="onScroll"
        :class="[$vuetify.breakpoint.smAndDown ? 'overflow-y-auto' : 'card']"
        max-height="250"
        color="transparent"
        elevation="0"
        id="scroll-card"
      >
        <h3 class="question income">
          How much do you earn (household income) in a year?
        </h3>

        <div class="finances-slider">
          <v-slider
            v-model="user.profile.householdIncome"
            thumb-label="always"
            min="57"
            max="62"
            color="white"
            track-color="white"
            default="57"
            key="sliderKey"
            @mouseup="$store.dispatch('updateInvestorProfile')"
          >
            <template v-slot:thumb-label="{ value }">
              <v-btn class="slider-label">
                <h4>Household Income</h4>
                <p>{{ incomeSliderValue[value] }}</p>
                <div class="rectangle"></div>
              </v-btn>
            </template>
          </v-slider>
        </div>

        <!-- Investable Wealth       -->
        <h3 class="question invest">How much investable wealth do you have?</h3>

        <div class="finances-slider invest">
          <v-slider
            v-model="user.profile.investableWealth"
            thumb-label="always"
            min="57"
            max="62"
            color="white"
            track-color="white"
            default="57"
            key="sliderKey"
            @mouseup="$store.dispatch('updateInvestorProfile')"
          >
            <template v-slot:thumb-label="{ value }">
              <v-btn class="slider-label">
                <h4>Savings, stocks...</h4>
                <p>{{ investSliderValue[value] }}</p>
                <div class="rectangle"></div>
              </v-btn>
            </template>
          </v-slider>
        </div>
      </v-card>
    </v-container>

    <!-- desktop -->
    <v-container v-if="$vuetify.breakpoint.smAndUp">
      <h3 class="question">
        How much do you earn (household income) in a year?
      </h3>
      <p class="directions">
        Tap on the range that best represents your household income.
      </p>

      <v-btn-toggle
        v-model="user.profile.householdIncome"
        rounded
        class="toggle-select icon-toggle"
        @change="$store.dispatch('updateInvestorProfile')"
        :value-comparator="simpleMatch"
      >
        <v-btn
          :value="income.id"
          v-for="income in data.householdIncome"
          :key="income.id"
        >
          {{ income.title }}
          <svg-img
            svgUrl="/img/icons/icon-household-income.svg"
            class="drawer-icon"
          ></svg-img>
        </v-btn>
      </v-btn-toggle>
      <h3 class="question">How much investable wealth do you have?</h3>
      <p class="directions">
        Include things like savings, inheritance, stocks, and 401(k)s.
      </p>

      <v-btn-toggle
        v-model="user.profile.investableWealth"
        rounded
        class="toggle-select icon-toggle"
        @change="$store.dispatch('updateInvestorProfile')"
      >
        <v-btn
          :value="wealth.id"
          v-for="wealth in data.investableWealth"
          :key="wealth.id"
        >
          {{ wealth.title }}
          <svg-img
            svgUrl="/img/icons/icon-investable-wealth.svg"
            class="drawer-icon"
          ></svg-img>
        </v-btn>
      </v-btn-toggle>
    </v-container>
    <match-navigation :navProgress="progressIndex"></match-navigation>
  </v-container>
</template>

<script>
  import MatchNavigation from '@/components/MatchNavigation'

  export default {
    name: 'InvestorFinances',
    components: { MatchNavigation },
    data() {
      return {
        priceRange: '$0-$40k',
        sliderKey: 57,
        incomeSliderValue: {
          57: '$0-$40k',
          58: '$40k-$75k',
          59: '$75k-$125k',
          60: '$125k-$250k',
          61: '$250k-$1m',
          62: '>$1m',
        },
        investSliderValue: {
          57: '$0-$50k',
          58: '$50k-$100k',
          59: '$100k-$250',
          60: '$250k-$500k',
          61: '$500-$1m',
          62: '>$1m',
        },
      }
    },
    computed: {
      progressIndex() {
        const index = this.data.settings.matchingPageOrder.findIndex(x => x.stepSlug === 'investor-finances')
        return index+1
      }
    },
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .toggle-select {
    margin: 4em 0 4em;

    button {
      max-width: 7em;
      flex-grow: 1;
      height: 3.75em !important;

      &.v-btn--active {
        position: relative;
        &:before {
          background-color: @brand-yellow;
          opacity: 1;
        }
      }
    }
  }
  // Mobile Custom Slider

  .finances {
    h2 {
      margin-top: -2rem;
    }
    .mobile {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      .card {
        margin-bottom: 3rem;
      }
      #scroll-card {
        margin-top: -1rem;
        * {
          transform: scale(0.97);
        }
      }
      h3.question.income {
        margin: 0rem 0 -4.8rem 0;
      }
      h3.question.invest {
        margin: 1rem 0 -4.8rem 0;
      }

      .finances-slider {
        margin-top: 11rem;
        width: 280px;
        margin-left: 9.2%;
        .v-slider__thumb-label {
          &.white {
            background: none !important;
          }
          transform: unset !important;
        }
        .v-slider__track-container {
          color: @brand-yellow;
          background-color: @brand-yellow;
          border: 9px solid @brand-yellow;
          border-radius: 10px;
          &::before {
            content: '-------';
            top: -9px;
            left: -56px;
            position: absolute;
            color: rgba(248, 213, 67, 0);
            background-color: #f8d543;
            border: 8px solid #f8d543;
            border-radius: 10px;
            width: 61px;
            height: 18px;
          }
          &::after {
            right: -56px;
            content: '-------';
            color: #f8d543;
            background-color: #f8d543;
            border-left: 9px solid #f8d543;
            border-radius: 10px;
            width: 62px;
            top: -9px;
            position: absolute;
            height: 18px;
          }
        }
        .v-slider__thumb.white {
          display: none;
        }
      }
      .v-btn:is(.toggle),
      .v-btn--is-elevated,
      .v-btn:not(.app-bar):not(.toggle):not(.v-btn--icon):not(.v-btn-toggle
          > .v-btn):not(.v-btn--text).theme--light.v-btn {
        color: white;
        background-color: white;
        margin-top: 1.9rem;
      }
      .v-btn__content {
        display: flex !important;
        flex-direction: column;
        padding: 0;
        margin: 0 -6rem;
      }
      .v-btn.slider-label {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 9.2rem;
        height: 5rem;
        top: -0.7rem !important;
        padding: 1em 2em;
        margin-bottom: 9rem;
        background: white;
        color: @brand-dark-gray;
        box-shadow: none !important;
        text-decoration: none;
        position: relative;
        border: solid white 3px !important;
        border-radius: 0.2rem !important;
        transform: rotate(45deg) translateX(40%) translateY(-21px) scale(0.75);
        .rectangle {
          position: absolute;
          content: '';
          height: 2.6rem;
          width: 0.5rem;
          background-color: white;
          color: white;
          margin: 8.7rem auto 0rem auto;
        }
        h4 {
          font-size: 12px;
          color: black;
          text-transform: capitalize;
          margin-top: 1rem;
        }
        &:after {
          content: '';
          height: 1.5em;
          width: 1.5em;
          background-color: white;
          position: absolute;
          top: 3.8rem;
          transform: rotate(45deg);
          box-shadow: rgba(0, 0, 0, 0) 0 0 8px -5px;
        }
      }
    }
  }
</style>
