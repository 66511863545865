<template>
  <div
    id="resources-wrap"
    :class="panel || this.$route.name == 'Resources' ? 'panel' : ''"
  >
    <v-container class="resources">
      <v-row>
        <v-col cols="12" sm="5">
          <h4>Free resources and education</h4>
          <h2 class="invert">You can now choose wisely</h2>
          <img
            class="resources-graphic"
            src="/img/graphic-money-coins-stock.svg"
            alt=""
          />
        </v-col>
        <v-col class="spacer" cols="0" sm="1">
          <v-divider class="mx-2 dashed light" vertical></v-divider>
        </v-col>
        <v-col cols="12" sm="6">
          <resource-filter v-if="filterable"></resource-filter>
          <dl>
            <template v-for="resource in filteredResources">
              <dt :key="'dt-' + resource.id">
                <router-link :to="'/' + resource.uri">
                  <img
                    v-if="resource.svgIcon[0]"
                    :src="resource.svgIcon[0].url"
                    :title="resource.svgIcon[0].title"
                  />
                  <img v-else src="/img/graphic-voicebubbles.svg" />

                  {{ resource.title }}
                </router-link>
              </dt>
              <dd :key="'dd-' + resource.id">
                {{ resource.description }}
              </dd>
            </template>
          </dl>
          <v-btn
            v-if="
              filterable && calculated.resourcesFound > calculated.resourceLimit
            "
            @click="calculated.resourceLimit += RESOURCE_COUNT_PER_PAGE"
            text
          >
            Load More Resources
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import ResourceFilter from '@/components/ResourceFilter'

  export default {
    name: 'Resources',
    components: { ResourceFilter },
    props: {
      filterable: Boolean,
      panel: Boolean,
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  #resources-wrap {
    background-color: @brand-blue-gray;

    h2 {
      font-size: 48px;
      font-weight: 400;
      font-size: @font-size-h4;
      margin-bottom: 0.75em;
      text-align: center;
    }

    h4 {
      color: @brand-yellow;
      font-size: @font-size-base;
      font-weight: 700;
      text-align: center;
    }

    .filter-form {
      .v-btn {
        width: 100%;
      }

      .col-9 {
        padding-left: 0;
      }
    }

    .resources-graphic {
      width: 80vw;
      max-width: 100%;
    }

    .spacer {
      display: none;
    }

    dt {
      a {
        color: @brand-yellow;
        font-family: @font-family-serif;
        font-size: @font-size-upbit;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.75em 0;

        img {
          height: 1.75em;
          margin-right: 0.5em;
        }
      }
    }

    dd {
      color: @brand-white;
      margin-bottom: 1.5em;
    }

    .mx-2 {
      margin: 0 3em !important;
    }

    .resources {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      margin: 2em auto;
    }

    .v-btn--text {
      color: @brand-yellow;
      font-family: @font-family-base;
      padding: 0;
      text-decoration: underline;
      text-transform: none;
      letter-spacing: 0;
      margin-top: 1em;
    }

    @media screen and (min-width: 600px) {
      h2,
      h4 {
        text-align: right;
      }
      h2 {
        font-size: @font-size-huge;
      }

      h4 {
        font-size: @font-size-h4;
      }
      .spacer {
        display: block;
      }
    }

    @media screen and (min-width: 960px) {
      .resources-graphic {
      }
    }
  }
</style>
