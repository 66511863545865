<template>
  <div class="resource-filter">
    <v-text-field
      outlined
      clearable
      v-model="calculated.resourceKeyword"
      placeholder="Keyword or Terms"
      id="filterResources"
      append-icon="mdi-magnify"
      @input="resetResourceLimit()"
    ></v-text-field>
  </div>
</template>

<script>
  // import HelpTip from '@/components/HelpTip'

  export default {
    name: 'ResourceFilter',
    // components: { HelpTip },
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .resource-filter {
    fieldset {
      background: white;
    }
  }
</style>
