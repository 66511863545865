<template>
  <fragment>
    <h4>Other Resources &amp; Guides</h4>
    <resource-filter></resource-filter>

    <ul>
      <li v-for="resource in filteredResources" :key="resource.id">
        <router-link :to="'/' + resource.uri">{{ resource.title }}</router-link>
      </li>
      <li></li>
      <li>
        <h4><a href="/resources">Additional Resources</a></h4>
      </li>
    </ul>
  </fragment>
</template>

<script>
  import { Fragment } from 'vue-fragment'
  import ResourceFilter from '@/components/ResourceFilter'

  export default {
    name: 'ResourceOther',
    components: { ResourceFilter, Fragment },
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';
</style>
