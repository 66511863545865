<template>
  <div class="progress-status">
    <v-icon color="#243746" small>mdi-check</v-icon>
  </div>
</template>

<script>
  export default {
    name: 'ProgressStatus',
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .progress-status {
    background-color: #dadada;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1em;
    width: 1em;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);

    .v-icon {
      opacity: 0.5;
    }
  }
  .current {
    .progress-status {
      .v-icon {
        opacity: 1;
      }
    }
  }
  @media screen and (min-width: 600px) {
    .progress-status {
      height: 1.5em;
      width: 1.5em;
    }
  }
</style>
