<template>
  <fragment>
    <template v-for="content in contentArray">
      <h3 v-if="content.typeHandle == 'headline'" :key="content.id">
        {{ content.text }}
      </h3>
      <div
        class="images-comp"
        v-if="content.typeHandle == 'images'"
        :key="content.id"
      >
        <img
          v-for="image in content.images"
          :key="image.id"
          :src="image[content.transform]"
          :onclick="`window.open('${image.linkTo}', '_blank')`"
          :title="image.title"
          :width="image[content.transform + '_width']"
          :height="image[content.transform + '_height']"
        />
      </div>
      <ol
        v-else-if="content.typeHandle == 'list' && content.listType == 'ol'"
        :key="content.id"
      >
        <li v-for="item in content.items" :key="item.id">
          <h4>{{ item.heading }}</h4>
          <div v-html="item.details"></div>
        </li>
      </ol>
      <hr v-if="content.typeHandle == 'divider'" :key="content.id" />
      <div
        v-if="content.typeHandle == 'richText'"
        :key="content.id"
        v-html="content.text"
      ></div>
      <template v-if="content.typeHandle == 'bullets'">
        <h4 :key="'h4-' + content.id">{{ content.heading }}</h4>
        <ul :key="'ul-' + content.id">
          <li v-for="item in content.items" :key="item.id">
            {{ item.item }}
          </li>
        </ul>
      </template>
    </template>
  </fragment>
</template>

<script>
  import { Fragment } from 'vue-fragment'

  export default {
    name: 'ContentMatrix',
    components: { Fragment },
    props: {
      contentArray: Array,
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
    updated() {},
    mounted() {},
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .images-comp {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    img {
      margin: 0 1em 0.5em 0;
      cursor: pointer;
    }
  }
</style>
